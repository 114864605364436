import { HStack, Text, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react" ;
import React from "react" ;

import "foundation-ts/number" ;
import { $trim } from "foundation-ts/strings" ;
import { TSDate } from "foundation-ts/tsdate" ;

import { CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes" ;
import { DriveItemStructure } from "g1-commons/lib/doxecureTypes" ;

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { useGetFolderContent } from "../../hooks/drives/userGetFolderContent" ;
import { G1Icon } from "../../utils/icons" ;

interface DriveExplorerProps {
    drive: CloudSubscriptionDto;
    folder: string;
    onSelectFile?: (file: DriveItemStructure) => void;
    onSelectFolder?: (folder: DriveItemStructure) => void;
}

const isFileSelectable = (entry: DriveItemStructure) => {
    return $trim(entry.mimeType) === "application/pdf";
};

export const DriveExplorer = ({ drive, folder, onSelectFile, onSelectFolder }: DriveExplorerProps) => {
    const { data: entries } = useGetFolderContent(drive.apid, folder);

    const handleSelectEntry = (entry: DriveItemStructure) => {
        if (entry.isFolder) {
            onSelectFolder && onSelectFolder(entry);
        } else if (isFileSelectable(entry)) {
            onSelectFile && onSelectFile(entry);
        }
    };

    return (
        <>
            {entries && (
                <Table size="sm">
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Taille</Th>
                            <Th>Modifié le</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {entries.map((entry) => {
                            const opacity = entry.isFolder || isFileSelectable(entry) ? 1 : 0.5;
                            const cursor = entry.isFolder || isFileSelectable(entry) ? "pointer" : "unset";
                            const lastModificationDate = entry.lastModificationDate
                                ? new TSDate(new Date(entry.lastModificationDate)).toString() // TODO: Make DriveItemStructure to use isodate
                                : "";
                            return (
                                <Tr key={entry.id} onClick={() => handleSelectEntry(entry)} cursor={cursor} opacity={opacity}>
                                    <Td>
                                        {entry.isFolder ? (<HStack><G1Icon.Folder color={$g1Color('global.icons.navigation.folder.write')}/><Text fontSize="sm">{entry.name}</Text></HStack>) : entry.name}
                                    </Td>
                                    <Td>{!entry.isFolder && entry.size && entry.size.octets(1)}</Td>
                                    <Td>{lastModificationDate}</Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            )}
        </>
    );
};
