import { useToast } from "@chakra-ui/react" ;
import React, { useEffect, useState } from "react" ;

import { $ok } from "foundation-ts/commons" ;
import { Nullable } from "foundation-ts/types";
import { ConsumptionsStructure } from "g1-commons/lib/doxecureTypes";

import { ConsumptionsBox } from "../../components/ConsumptionsBox/ConsumptionsBox";
import { getUserUuid } from "../../services" ;
import { exportConsumptions, getConsumptions, getCurrentConsumption } from "../../services/stats.service";
import { defaultErrorToast } from "../../utils/toast" ;

export const ConsumptionsTab = () => {
    const toast = useToast() ;
    const [nbQualified, setNbQualified] = useState<number>(0) ;
    const [nbAdvanced, setNbAdvanced] = useState<number>(0) ;
    const [consumptions, setConsumptions] = useState<Nullable<ConsumptionsStructure>>(null) ;

    useEffect(() => {
        const currentConsumption = async () => {
            const currentConsumption = await getCurrentConsumption(getUserUuid()!) ;
            setNbQualified($ok(consumptions) ? currentConsumption!.qualified : -1) ;
            setNbAdvanced($ok(consumptions) ? currentConsumption!.advanced : -1) ;
        }

        const consumptions = async () => {
            const data = await getConsumptions(getUserUuid()!) ;
            setConsumptions($ok(data) ? data! : null) ;
        }

        currentConsumption() ;
        consumptions() ;
    }, []);

    const onExportConsumptions = async () => {
        try {
            exportConsumptions(getUserUuid()!) ;
        } catch (error) {
            toast(defaultErrorToast("Problème rencontré lors de l'export des données")) ;
        }
    } ;

    return (
        <ConsumptionsBox nbAdvanced={nbAdvanced} nbQualified={nbQualified} consumptions={consumptions} onExport={onExportConsumptions} />
                
    ) ;
} ;