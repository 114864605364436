import { Text, VStack } from "@chakra-ui/layout";
import { Box, Input } from "@chakra-ui/react";

import React, { ChangeEvent, Dispatch, useRef } from "react";

import { UUID } from "foundation-ts/types";
import { $map } from "foundation-ts/array";
import { $count, $defined, $isuuid, $keys, $ok } from "foundation-ts/commons";
import { CloudSubscriptionDto, SessionDto, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { BCADocumentFile, BCAValidDocumentInfo } from "bca-client-signature/lib/BCADocuments";
import { selectFilesOnDevice } from "bca-client-signature/lib/BCAQSCD";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { G1Button } from "../../components/G1Button";
import { UploadFailModal } from "../../components/Modals/UploadFailModal";
import { BCABrowserDocumentFile } from "../../utils/BCABrowserDocumentFile";

import { DocumentsToSignBox } from "./DocumentsToSignBox";
import { SignatureBox } from "./SignatureBox";


// TODO: do we keep openFailModal here ?
interface SignatureCreationPanelProps {
    session: SessionDto ;
    userProfile: UserDto ;
    openFailModal: boolean ;
    optionInvisibleSwitchChecked?: boolean ;
    officeDocuments?: boolean ;
    onAddSignerClick: () => void ;
    onAddSenderClick: () => void ;
    onChangeSession: (oldSession:SessionDto, newSession:SessionDto) => void ;
    onRemoveSigner: (apid: UUID) => void ;
    onSaveAsDraft: () => void ;
    onSelectDrive: (drive: CloudSubscriptionDto) => void ;
    onPutToSignature: () => void ;
    onToggleInvisibleSwitch: () => void ;
    onUploadFiles: (files:BCADocumentFile[]|string) => void ;
    onViewDocument: (apid: UUID) => void ;
    setOpenFailModal: (open: boolean) => void ;
    setSession: Dispatch<React.SetStateAction<SessionDto>> ;
}

export const SessionCreationPanel = ({
    session,
    userProfile,
    openFailModal,
    officeDocuments,
    optionInvisibleSwitchChecked,
    onAddSenderClick,
    onAddSignerClick,  
    onChangeSession,
    onPutToSignature,
    onRemoveSigner,
    onSaveAsDraft,
    onSelectDrive,
    onToggleInvisibleSwitch,
    onUploadFiles,
    onViewDocument,
    setOpenFailModal,
    setSession,
}: SignatureCreationPanelProps) => {
    // ___________Documents
    // Ouverture de la popup de selection
    const inputFile = useRef<HTMLInputElement>(null) ;
  
    const onBrowserUploadFiles = (e: ChangeEvent<HTMLInputElement>) => {
        onUploadFiles($map(e?.target?.files, f => new BCABrowserDocumentFile(f))) ;
    }

    const handleClickUploadFiles = async () => {
        const fileTypes = !officeDocuments ? ['.pdf'] : $map($keys(BCAValidDocumentInfo), e => '.'+e) ;
        const files = await selectFilesOnDevice(fileTypes, true) ;
        if ($defined(files)) { onUploadFiles(files!) ; } 
        else if (inputFile && inputFile.current !== null) {
            inputFile.current.click();
        } 
    };
    const isSessionValid = $count(session.documents) > 0 && $isuuid(session.expeditor.apid) && $count(session.signatures) > 0 && session.title.length > 0 ;
    return (
        <>
            <ColumnTitle color={$g1Color('global.write')}>
                <Text>Mise à la signature</Text>
            </ColumnTitle>

            <VStack spacing="5">
                <SignatureBox
                    session={session}
                    displayCalendar={true}
                    optionInvisibleSwitchChecked={optionInvisibleSwitchChecked}
                    onAddSenderClick={onAddSenderClick}
                    onAddSignerClick={onAddSignerClick}
                    onRemoveSigner={onRemoveSigner}
                    onChangeSession={onChangeSession}
                    onToggleInvisibleSwitch={onToggleInvisibleSwitch}
                    setSession={setSession}
                />
                <DocumentsToSignBox
                    documents={$ok(session?.documents)?session!.documents!:[]}
                    drives={$ok(userProfile?.cloudSubscriptions)?userProfile!.cloudSubscriptions!:[]}
                    onSelectDrive={onSelectDrive}
                    onClickUploadFiles={handleClickUploadFiles}
                    onViewDocument={onViewDocument}
                />
                <Box display="flex" flexDirection={{ base: "column", sm: "row" }} width="100%" gap="10px">
                    <G1Button component="form" variant="alternate" title="Sauvegarder brouillon" width={{ base: "100%", sm: "50%" }} height="60px" marginRight={{ base: "unset", sm: "2%" }} marginBottom={{ base: "5%", sm: "unset" }} rounded="xl" fontSize="lg" disabled={session.title.length === 0} onClick={onSaveAsDraft} />
                    <G1Button component="form" variant="important" title="Mettre en signature" width={{ base: "100%", sm: "50%" }} height="60px" marginLeft={{ base: "unset", sm: "2%" }} rounded="xl" fontSize="lg" disabled={!isSessionValid} onClick={onPutToSignature} />
                </Box>
                <UploadFailModal isOpen={openFailModal} onClose={() => setOpenFailModal(false)} />
                <Input type="file" display="none" ref={inputFile} onChange={onBrowserUploadFiles} accept={!officeDocuments ? '.pdf' : '.'+$keys(BCAValidDocumentInfo).join(',.')} multiple />
            </VStack>
        </>
    );
};