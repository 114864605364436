import { $count } from "foundation-ts/commons";
import { CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes";

import React from "react";

import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
} from "@chakra-ui/react";

import { getIconForCloudProvider } from "../../utils/Provider";
import { G1Button } from "../../components/G1Button";

interface AddDocumentModalProps {
    isOpen: boolean;
    drives: CloudSubscriptionDto[];
    onClose: () => void;
    onClickUploadFiles: () => void;
    onSelectDrive: (drive: CloudSubscriptionDto) => void;
}

export const AddDocumentModal = ({ drives, isOpen, onClose, onSelectDrive, onClickUploadFiles }: AddDocumentModalProps) => {
    return (
        <Modal onClose={onClose} size="xs" isOpen={isOpen} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Ajouter un document</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {$count(drives) > 0 ? 
                        "Uploader un ou plusieurs documents de votre appareil ou les choisirs depuis l'un de vos clouds connectés." :
                        "Uploader un ou plusieurs documents de votre appareil."
                    }
                </ModalBody>
                {
                 // WARNING: the next box did contain columns={3} spacing="3" 
                }
                <Box
                    w="100%"
                    mt="6"
                    display="grid"
                    gridGap="10px"
                    justifyContent="stretch"
                    margin="1em 0"
                    padding="0 10%"
                >
                    <Stack align="stretch">
                        <G1Button component="global" variant="optional" title="Sélectionner de(s) document(s)" fontSize="xs" padding="unset" onClick={onClickUploadFiles} />
                    </Stack>
                    {drives.map((drive) => {
                        const providerIcon = getIconForCloudProvider(drive.supplier);
                        return (
                            <Stack key={drive.apid} align="stretch">
                                <G1Button component="global" variant="optional" title={drive.identifier} fontSize="sm" leftIcon={providerIcon} padding="unset" onClick={() => onSelectDrive(drive)} />
                            </Stack>
                        );
                    })}
                </Box>
                <ModalFooter margin="auto"></ModalFooter>
            </ModalContent>
        </Modal>
    );
};
