import { useEffect, useState } from "react";
import { $ok } from "foundation-ts/commons";



export function usePageVisibilityChange() {
    const [isVisible, setIsVisible] = useState(isDocumentHidden());
    const onVisibilityChange = () => setIsVisible(isDocumentHidden());

    useEffect(() => {
        const visibilityChange = browserVisibilityProp();
        if ($ok(visibilityChange)) {
            document.addEventListener(visibilityChange!, onVisibilityChange, false);

            return () => {
                document.removeEventListener(visibilityChange!, onVisibilityChange);
            };
        }
    });

    return isVisible;
}

function isDocumentHidden(): boolean {
    const prop = browserDocumentHiddenProp();
    return $ok(prop) ? !document[prop!] : false;
}


function browserVisibilityProp():string|undefined {
    if (typeof document !== 'undefined') {
        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            return "visibilitychange";
        } else if (typeof (document as any).msHidden !== "undefined") {
            return "msvisibilitychange";
        } else if (typeof (document as any).webkitHidden !== "undefined") {
            return "webkitvisibilitychange";
        }
    }
    return undefined ;
}

function browserDocumentHiddenProp():string|undefined {
    if (typeof document !== 'undefined') {
        if (typeof document.hidden !== "undefined") {
            return "hidden";
        } else if (typeof (document as any).msHidden !== "undefined") {
            return "msHidden";
        } else if (typeof (document as any).webkitHidden !== "undefined") {
            return "webkitHidden";
        }    
    }
    return undefined ;
}
