import { Box, Text } from "@chakra-ui/layout";
import React from "react";

import { StyleProps } from "@chakra-ui/react";
interface SectionBoxHeaderProps {
    title: string ;
    description: string ;
    color: StyleProps["color"] ;
    borderBottomColor: string ;
}

export const SectionBoxHeader = (props: SectionBoxHeaderProps) => () => {
    const { title, description, color, borderBottomColor } = props ;
    return (
        <Box px="4" py="2" borderBottom="1px" borderBottomColor={borderBottomColor} minH="6em">
            <Text color={color}>{title}</Text>
            <Text color={color} fontWeight="100">
                {description}
            </Text>
        </Box>
    ) ;
} ;