import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { G1Button } from "../G1Button";
interface ConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAccept?: () => void;
    headerText?: string;
    bodyText?: string;
    data?: any;
}
export const ConfirmModal = (props: ConfirmModalProps) => {
    const { isOpen, onClose, headerText, bodyText, onAccept } = props;
    return (
        <Modal onClose={onClose} size="xs" isOpen={isOpen} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{headerText}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{bodyText}</ModalBody>
                <ModalFooter margin="auto">
                    <G1Button component="global" variant="normal" title="Oui" marginRight="2%" width="100px" onClick={onAccept} />
                    <G1Button component="global" variant="cancel" title="non" marginRight="2%" width="100px" onClick={onClose} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
