import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { useMutation } from "react-query";

import { getUserUuid } from "../../services/authentication.service";
import { connectUserToDrive } from "../../services/drive.service";

export const useConnectToDrive = () => {
  return useMutation(({ provider, redirectUri }: { provider: ServiceSupplier; redirectUri: string }) =>
    connectUserToDrive(getUserUuid(), provider, redirectUri)
  );
};
