import { Nullable, UUID } from "foundation-ts/types";

import { DriveItemStructure } from "g1-commons/lib/doxecureTypes";

import { useQuery } from "react-query";

import { getUserUuid } from "../../services/authentication.service";
import { getDriveFolderContent } from "../../services/drive.service";

export const useGetFolderContent = (userChannelID: Nullable<UUID>, folder: string) => {
  return useQuery<Array<DriveItemStructure>>(["drives", userChannelID, folder], () =>
    getDriveFolderContent(getUserUuid(), userChannelID, folder)
  );
};
