import React from "react";

import { $ok } from "foundation-ts/commons";
import { SessionActorType } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { getUserUuid } from "../../services";
import { okOrError, optlog } from "../../utils/functions";
import { componentMap, sectionMap } from "../../utils/TypesAndConstants";

import { Dashboard } from "../generics/Dashboard";

// @ts-ignore
const searchSectionInformations = (category: SectionCategory): SectionInfos => {
    const object = componentMap.get(category) ;
    const component = `list.searchresults.${category === "urgent" ? "urgent" : object?.component}`;
    const header = $ok(category) ? sectionMap.get(category!) : undefined;

    return {
        title:header?.title || `*** DEBUG SECTION ${category} ***`,
        description:header?.description || `*** SECTION DESCRIPTION ***`,
        style: {
            bg: $g1Color(`${component}.bg`),
            color: $g1Color(`${component}.write`),
            borderTopColor: $g1Color('list.spacer'),
            rounded: { base: "lg" },
        },
        itemStyle: {
            borderTop: "1px",
            borderTopColor: $g1Color('list.spacer'),
            hover: { bg: $g1Color(`${component}.hover.bg`), color: $g1Color(`${component}.hover.write`) },
            select: { bg: $g1Color(`${component}.select.bg`), color: $g1Color(`${component}.select.write`) },
        }
    }
}

export interface SearchViewProps {
    search?: string ;
}

export const SearchView = ({ search }: SearchViewProps) => {
    const profileId = getUserUuid() ;
    optlog(`+++ SearchView initialized with profile ID '${profileId}'`) ;

    return okOrError(
        profileId,
        (<Dashboard 
            // we don't pass any category here, which means that we want all 
            // the categories for all the roles
            title="Ma recherche"
            emptyMessage="Recherche infructueuse"
            search={search}
            activateSearch={true}
            role={SessionActorType.All} 
            sectionInfos={searchSectionInformations} 
            profileId={profileId!}
        />),
        false,
        "Impossible d'afficher la fenêtre de recherche car l'identifiant de profil de la personne connectée n'a pas pu être récupéré.",
        "Connexion invalide"
    ) ;    
};


