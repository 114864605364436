import { Box, Text } from "@chakra-ui/layout";
import { Flex, HStack, Stack, Center, Checkbox } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";

import { uint8 } from "foundation-ts/types";
import { $length } from "foundation-ts/commons";
import { DocumentDto, SignatureDto } from "g1-commons/lib/doxecureClientTypes";
import { SignatureMode } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { G1Button } from "../../components/G1Button";
import { Viewer } from "../../components/Viewer/Viewer";

import { okOrEmptyCard } from "../../utils/functions";
import { G1Icon } from "../../utils/icons";

interface DocumentPanelProps {
    document: DocumentDto ;
    signatures: SignatureDto[] ;
    message?: string ;
    signatureMode?: SignatureMode ;
    onRemoveDocument: () => void ;
    onChangeSignatureLines: (lines: uint8) => void; 
    onBack: () => void ;
}

export const DocumentPanel = (props: DocumentPanelProps) => {
    const { document, signatures, signatureMode, message, onBack, onRemoveDocument, onChangeSignatureLines } = props;
    const [isChecked, setIsChecked] = useState(false) ;

    const handleClickCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked) ;
        onChangeSignatureLines((e.target.checked ? 0 : 3) as uint8) ;
    }

    return (
        <>
            <ColumnTitle color={$g1Color('global.write')}>
                <Flex alignItems="center" as="button" onClick={onBack} width="100%">
                    <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                    <Text>Mettre à la signature</Text>
                </Flex>
            </ColumnTitle>
            <Box marginBottom="2">
                {okOrEmptyCard(document.apid || document.localUUID,
                    <HStack mt="unset" height="auto" display="flex" flexDirection={{ base: "column", sm: "row" }} justifyContent="space-between">
                        {signatureMode === SignatureMode.Visible ? <Checkbox isChecked={isChecked} onChange={handleClickCheckbox}>Placer les signatures sur une nouvelle page</Checkbox>
                            : <Text fontSize="medium">Vous avez choisi le mode invisible. Les signataires sont pris en compte mais ils ne sont pas affichés dans le document.</Text>
                        }
                        <Stack margin="5%" display="flex" justifyContent="space-around" width={{ base: "100%", sm: "unset" }} height="100%" flexDirection={{ base: "row", sm: "column" }} spacing={{ base: "unset", sm: "2" }} alignItems="center" paddingTop={{ base: "2%", sm: "unset" }}>
                            <G1Button component="form" variant="delete" title="Retirer" width="100px" fontSize="md" onClick={() => onRemoveDocument()} />
                        </Stack>
                    </HStack>)
                }
            </Box>
            {okOrEmptyCard($length(message)>0,
                <Box marginBottom="5px">
                    <Text fontSize="0.8em" lineHeight="shorter" color="orange" textAlign="center">
                        {message}
                    </Text>
                </Box>
            )}
            <Viewer document={document} forceMobile={true} originalScale={1} signatures={signatureMode === SignatureMode.Visible ? signatures : []} immutable={false} lastPageSignRows={document.lastPageSignRows} />
        </>
    );
};
