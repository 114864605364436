import { Box, Center, Flex, HStack, Stack, StyleProps, Text, useToast, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";

import { $count, $ok } from "foundation-ts/commons";
import { $timeBetweenDates } from "foundation-ts/date";
import { TSDay } from "foundation-ts/tsdate";
import { Nullable } from "foundation-ts/types";
import { SessionDto } from "g1-commons/lib/doxecureClientTypes";
import { SignatureType } from "g1-commons/lib/doxecureModelTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { SessionCard } from "../../components/Cards/SessionCard";
import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { AsyncConfirmModal, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";
import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";
import { SectionBox } from "../../components/SectionBox/SectionBox";
import { G1Button } from "../../components/G1Button";
import { SeaShellBox } from "../../components/SeaShellBox";

import useTemplateSessions from "../../hooks/useTemplateSessions";
import { getUserUuid } from "../../services";
import { okOrError, optlog } from "../../utils/functions";
import { CustomItemType } from "../../utils/TypesAndConstants";
import { G1Icon } from "../../utils/icons";
import { deleteTemplateSession } from "../../services/session.service";
import { defaultErrorToast, defaultSuccessToast } from "../../utils/toast";

const templateSessionsSectionStyle: StyleProps = {
    bg: $g1Color('list.templates.bg'),
    color: $g1Color('list.templates.write'),
    fontWeight: "bold",
    overflow: "hidden",
    rounded: "xl",
    width: "100%",
};

const templateSessionStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.templates.hover.bg'), color: $g1Color('list.templates.hover.write') },
    select: { bg: $g1Color('list.templates.select.bg'), color: $g1Color('list.templates.select.write') },
}

export const TemplateSessionsView = () => {
    const { data: templates } = useTemplateSessions() ;
    const [templateSessions, setTemplateSessions] = useState<SessionDto[]>([]) ;
    const [selectedTemplateSession, setSelectedTemplateSession] = useState<Nullable<SessionDto>>(null) ;
    const [confirmModal, setConfirmModal] = useState(DefaultAsyncConfirmModalState) ;
    const toast = useToast() ;
    const profileId = getUserUuid() ;
    optlog(`+++ TemplatesView initialized with profile ID '${profileId}'`) ;

    const onView = (session: SessionDto) => {
        setSelectedTemplateSession(session) ;
    }

    const onDelete = () => {
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous sûr de vouloir supprimer le modèle '${selectedTemplateSession?.title}' ?`,
            onAccept: async () => {
                if (await deleteTemplateSession(selectedTemplateSession!.apid!)) {
                    toast(defaultSuccessToast("Le modèle a été supprimé")) ;
                    const index = templates?.findIndex(session => session.apid === selectedTemplateSession?.apid) ;
                    if (index !== -1) {
                        templates?.splice(index!, 1) ;
                        setTemplateSessions(templates!) ;
                        setSelectedTemplateSession(null) ;
                    }
                } else {
                    toast(defaultErrorToast("Erreur lors de la suppression du modèle")) ;
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        }) ;
    }

    const onChange = () => {
        navigate(`/app/newTemplate/${selectedTemplateSession?.apid}`) ;
    }

    const onCreateDraft = () => {
        navigate(`/app/newSignature/${selectedTemplateSession?.apid}`) ;
    }

    const resetAndBack = () => {
        setSelectedTemplateSession(null) ;
    }

    const renderSessionsBox = (item: SessionDto) => {
        return <SessionCard key={item.apid} item={item} itemStyle={templateSessionStyle} onClick={() => onView(item)} /> ;
    } ;

    const renderFutureSigners = () => {
        return (
            <Box>
                <Text decoration="underline" fontSize="md">Liste des signataires :</Text>
                {selectedTemplateSession?.signatures?.map((signature, i) => 
                    <Text key={`futuresigner${i}`} fontSize="inherit">{`${signature.signer.firstName} ${signature.signer.lastName} ${(signature.signatureType === SignatureType.AnyQSCD) ? '(signature qualifiée)' : ''}`}</Text>
                )}
            </Box>
        ) ;       
    } ;

    useEffect(() => {
        if ($ok(templates)) {
            setTemplateSessions(templates!) ;
        }
    }, [templates]) ;

    return okOrError(
        profileId,
        <>
            <PageShellWithThreeColumns
                centralContent={
                    <>
                        <ColumnTitle color={$g1Color('global.write')}>
                            <Text>Modèles</Text>
                        </ColumnTitle>
                        <VStack align="stretch" spacing="5">
                            {$count(templateSessions) === 0 ? <Center width="100%" height="100%" color="gray"><h2>Aucun modèle de dossier de signature défini</h2></Center>
                                : <SectionBox items={templateSessions} sectionStyle={templateSessionsSectionStyle} renderItem={renderSessionsBox} />
                            }
                        </VStack>
                    </>
                }
                rightContent={
                    <>
                        <ColumnTitle color={$g1Color('global.write')} visibility={$ok(selectedTemplateSession) ? "visible" : "hidden"}>
                            <Flex alignItems="center" as="button" onClick={() => resetAndBack()}>
                                <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                                <Text>Retour</Text>
                            </Flex>
                        </ColumnTitle>
                        <SeaShellBox component="form" height="auto" visibility={$ok(selectedTemplateSession) ? "visible" : "hidden"}>
                            <HStack mt="unset" height="auto" display="flex" flexDirection={{ base: "column", sm: "row" }}>
                                <VStack align="stretch" width="100%">
                                    <Text fontWeight="bold">{selectedTemplateSession?.title}</Text>
                                    <Stack align="stretch" fontSize="md">
                                        <Text fontSize="inherit" fontStyle="italic">{selectedTemplateSession?.description}</Text>
                                        <Text fontSize="inherit">{`Mis à la signature par ${selectedTemplateSession?.expeditor.firstName.capitalize()} ${selectedTemplateSession?.expeditor.lastName.toUpperCase()}`}</Text>
                                        <Text fontSize="inherit">{`Durée de validité du dossier : ${$timeBetweenDates(selectedTemplateSession?.validity.start.dateWithoutTime(), selectedTemplateSession?.validity.end.dateWithoutTime()) / TSDay} jours`}</Text>
                                        {(selectedTemplateSession?.signatures?.length !== 0) && renderFutureSigners()}
                                    </Stack>
                                </VStack>

                                <Stack margin="5%" display="flex" justifyContent="center" width={{ base: "100%", sm: "unset" }} height="100%" flexDirection={{ base: "row", sm: "column" }} spacing={{ base: "unset", sm: "2" }} alignItems="center" paddingTop={{ base: "2%", sm: "unset" }}>
                                    <G1Button component="form" variant="normal" title="Modifier" width="150px" fontSize="md" onClick={onChange} />
                                    <G1Button component="form" variant="delete" title="Supprimer" width="150px" fontSize="md" onClick={onDelete} />
                                    <G1Button component="form" variant="alternate" title="Créer un brouillon" width="150px" fontSize="md" onClick={onCreateDraft} />
                                </Stack>
                            </HStack>
                        </SeaShellBox>
                    </>
                }
            />
            <AsyncConfirmModal message={confirmModal.message} onAccept={confirmModal.onAccept} onClose={confirmModal.onClose} />
        </>,
        false,
        "Impossible d'afficher la liste des modèles car l'identifiant de profil de la personne connectée n'a pas pu être récupéré.",
        "Connexion invalide"
    ) ;
};
