import React from "react";

import { SessionActorType } from "g1-commons/lib/doxecureTypes";

import { okOrError, optlog, defaultSectionInformations } from "../../utils/functions";
import { getUserUuid } from "../../services";

import { Dashboard } from "../generics/Dashboard";

export const CanceledDocumentsView = () => {
    const profileId = getUserUuid() ;
    optlog(`+++ CanceledDocumentsView initialized with profile ID '${profileId}'`) ;

    return okOrError(
        profileId,
        (<Dashboard 
            title="Documents résiliés"
            emptyMessage="Pas de documents résiliés"
            category="canceled"
            role={[SessionActorType.Creator, SessionActorType.Expeditor, SessionActorType.Signer]} 
            sectionInfos={defaultSectionInformations} 
            profileId={profileId!}
        />),
        false,
        "Impossible d'afficher la liste des documents résiliés, car l'identifiant de profil de la personne connectée n'a pas pu être récupéré.",
        "Connexion invalide"
    ) ;
};
