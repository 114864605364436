import { Text, VStack } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/react";

import React, { Dispatch } from "react";

import { UUID } from "foundation-ts/types";
import { $count, $isuuid, $ok } from "foundation-ts/commons";
import { SessionDto, UserDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { G1Button } from "../../components/G1Button";

import { SignatureBox } from "../signature/SignatureBox";


// TODO: do we keep openFailModal here ?
interface TemplateSessionCreationPanelProps {
    session: SessionDto ;
    userProfile: UserDto ;
    optionInvisibleSwitchChecked?: boolean ;
    onAddSignerClick: () => void ;
    onAddSenderClick: () => void ;
    onChangeSession: (oldSession:SessionDto, newSession:SessionDto) => void ;
    onRemoveSigner: (apid: UUID) => void ;
    onSaveTemplate: () => void ;
    onToggleInvisibleSwitch: () => void ;
    setSession: Dispatch<React.SetStateAction<SessionDto>> ;
}

export const TemplateSessionCreationPanel = ({
    session,
    userProfile,
    optionInvisibleSwitchChecked,
    onAddSenderClick,
    onAddSignerClick,  
    onChangeSession,
    onRemoveSigner,
    onSaveTemplate,
    onToggleInvisibleSwitch,
    setSession,
}: TemplateSessionCreationPanelProps) => {
    const isSessionValid = $isuuid(session.expeditor.apid) && $count(session.signatures) > 0 && session.title.length > 0 ;
    return (
        <>
            <ColumnTitle color={$g1Color('global.write')}>
                <Text>Création / Edition d'un modèle</Text>
            </ColumnTitle>

            <VStack spacing="5">
                <SignatureBox
                    session={session}
                    displayDuringTime={true}
                    optionInvisibleSwitchChecked={optionInvisibleSwitchChecked}
                    onAddSenderClick={onAddSenderClick}
                    onAddSignerClick={onAddSignerClick}
                    onRemoveSigner={onRemoveSigner}
                    onChangeSession={onChangeSession}
                    onToggleInvisibleSwitch={onToggleInvisibleSwitch}
                    setSession={setSession}
                />
                <Box display="flex" flexDirection={{ base: "column", sm: "row" }} width="100%" gap="10px" justifyContent="center">
                    <G1Button component="form" variant="important" title={$ok(session.apid) ? 'Mettre à jour' : 'Créer'} width={{ base: "100%", sm: "30%" }} height="60px" marginLeft={{ base: "unset", sm: "2%" }} rounded="xl" fontSize="lg" disabled={!isSessionValid} onClick={onSaveTemplate} />
                </Box>
            </VStack>
        </>
    );
};