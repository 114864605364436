import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";

import { $ok } from "foundation-ts/commons";
import { UUID } from "foundation-ts/types";
import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { AsyncConfirmModal, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";
import { CloudBaseModal } from "../../components/Modals/CloudBaseModal";
import { useConnectToDrive } from "../../hooks/drives/useConnectToDrive";
import { useDeconnectDrive } from "../../hooks/drives/useDeconnectDrive";
import useProfile from "../../hooks/useProfile";
import { getUserUuid } from "../../services";
import { cloudProviders } from "../../utils/Provider";
import { optlog, trueOrEmptyCard } from "../../utils/functions";
import { defaultErrorToast } from "../../utils/toast";

import { CloudSubscriptionsBox } from "./CloudSubscriptionsBox";

export const SettingsTab = () => {
    const profileId = getUserUuid() ;
    optlog(`+++ SettingsView initialized with profile ID '${profileId}'`) ;

    const [modal, setModal] = useState(DefaultAsyncConfirmModalState) ;
    const [openCloudModal, setOpenCloudModal] = useState(false) ;

    const { data: userProfile, isError:profileError, isLoading:profileLoading } = useProfile(profileId, 'SettingsView') ;

    const toast = useToast() ;

    const handleAddCloud = () => {
        setOpenCloudModal(true) ;
    };

    const { mutateAsync: connectToDrive } = useConnectToDrive();
    const { mutateAsync: deconnectDrive } = useDeconnectDrive();

    const handleSelectProvider = async (provider: ServiceSupplier) => {
        try {
            const authUrl = await connectToDrive({
                provider,
                redirectUri: window.location.href,
            }) ;
            document.location.href = authUrl ;
        } catch (e) {
            toast(defaultErrorToast((e as Error).message)) ;
        }
    };

    const handleRemoveCloud = async (cloudID: UUID) => {
        setModal({
            message: "Êtes-vous sûr de vouloir supprimer votre connexion cloud ?",
            onClose: async () => setModal(DefaultAsyncConfirmModalState),
            onAccept: async () => {
                try {
                    userProfile!.cloudSubscriptions = await deconnectDrive({
                        userID:profileId,
                        userChannelID:cloudID
                    }) ;
                } catch (e) {
                    toast(defaultErrorToast((e as Error).message)) ;
                }
                setModal(DefaultAsyncConfirmModalState);
            },
        }) ;
    } ;

    return trueOrEmptyCard(
        $ok(profileId) && $ok(userProfile),
        (
            <>
                <CloudSubscriptionsBox userProfile={userProfile!} onAdd={handleAddCloud} onRemove={handleRemoveCloud} />
                <CloudBaseModal
                    isOpen={openCloudModal}
                    onClose={() => setOpenCloudModal(false)}
                    headerTxt="Ajouter un cloud"
                    bodyTxt="Choisissez le cloud auquel vous souhaitez vous connecter"
                    providers={cloudProviders}
                    onSelect={handleSelectProvider}
                />
                <AsyncConfirmModal message={modal.message} onClose={modal.onClose} onAccept={modal.onAccept} />
            </>
        ),
        profileLoading,
        profileError || !$ok(profileId) || !$ok(userProfile),
        "Impossible de récupérer le profil de l'utilisateur",
        "Connexion invalide",
        "Chargement du profil de l'utilisateur"
    ) ;
} ;