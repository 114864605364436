import { StyleProps, Text } from "@chakra-ui/react";
import React from "react";

import { UUID } from "foundation-ts/types";
import { $ok } from "foundation-ts/commons";

import { CloudSubscriptionDto, UserDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { CloudCard } from "../../components/Cards/CloudCard";
import { SeaShellBox } from "../../components/SeaShellBox";
import { SectionBox } from "../../components/SectionBox/SectionBox";
import { SectionBoxFooter } from "../../components/SectionBox/SectionBoxFooter";

import { CustomItemType } from "../../utils/TypesAndConstants";

interface CloudSubscriptionsBoxProps {
    userProfile:UserDto;
    onRemove: (userChannelId: UUID) => void;
    onAdd: () => void;
}

const sectionStyle: StyleProps = {
    bg: $g1Color('list.clouds.bg'),
    color: $g1Color('list.clouds.write'),
    fontWeight: "bold",
    overflow: "hidden",
    rounded: "xl",
    width: "100%",
} ;

const itemStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.clouds.hover.bg'), color: $g1Color('list.clouds.hover.write') },
    select: { bg: $g1Color('list.clouds.select.bg'), color: $g1Color('list.clouds.select.write') }
} ;

export const CloudSubscriptionsBox = ({ userProfile, onAdd, onRemove }: CloudSubscriptionsBoxProps) => {

    const renderCloudCard = (item: CloudSubscriptionDto) => {
        return (<CloudCard
            key={item.apid}
            itemStyle={itemStyle}
            item={item}
            onClick={() => onRemove(item.apid)}
        />)
    };

    const renderAddCloudButton = () => {
        return <SectionBoxFooter component="clouds" title="Se connecter à un cloud" onClick={onAdd} /> ;
    } ;

    // TODO Ajouter des skeletons
    // TODO: verify that we can connect to clouds here even if we have no cloud available

    return (
        <SeaShellBox component="form">
            <Text width="100%" fontWeight="bold">Abonnement Cloud</Text>

            {$ok(userProfile.cloudSubscriptions) && (
                <SectionBox
                    items={userProfile.cloudSubscriptions}
                    sectionStyle={sectionStyle}
                    renderItem={renderCloudCard}
                    renderFooter={renderAddCloudButton}
                />
            )}
        </SeaShellBox>
    );
};
