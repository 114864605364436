import { Nullable, UUID } from "foundation-ts/types";

import { useMutation } from "react-query";

import { deconnectDrive } from "../../services/drive.service";
  
export const useDeconnectDrive = () => {
    return useMutation(({ userID, userChannelID }: { userID: Nullable<UUID>; userChannelID: Nullable<UUID> }) =>
        deconnectDrive(userID, userChannelID)
    );
  };
  
export default useDeconnectDrive ;
