import { UUID } from "foundation-ts/types";

import { useMutation, useQueryClient } from "react-query";

import { deleteOneSessionForUser } from "../services/session.service";
import { SectionsListQueryID } from "./hooksConstants";

const useDeleteSession = () => {
  const queryClient = useQueryClient();
  return useMutation((sessionId: UUID) => deleteOneSessionForUser(sessionId), {
    onSuccess: () => {
      // A la mise à jour d'une session, on récupère les sessions
      
      queryClient.invalidateQueries(SectionsListQueryID) ;
    },
  });
};

export default useDeleteSession;
