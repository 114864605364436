import { Text, useToast } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import React, { FormEvent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { navigate } from "gatsby";

import { Nullable, UUID } from "foundation-ts/types";
import { $UUID, $count, $length, $ok, $value } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { $map } from "foundation-ts/array";

import { SectionCategory, SessionActorType } from "g1-commons/lib/doxecureTypes";
import { SectionDto, SessionDto, SessionsQueryRestrictions } from "g1-commons/lib/doxecureClientTypes";

import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";
import { ConfirmModal } from "../../components/Modals/ConfirmModal";

import useSections from "../../hooks/useSections";
import useDeleteSession from "../../hooks/useDeleteSession";
import { usePageVisibilityChange } from "../../hooks/useVisibilityChange";
import { SectionsListQueryID } from "../../hooks/hooksConstants";
import { optlog, trueOrEmptyCard } from "../../utils/functions";
import { defaultErrorToast } from "../../utils/toast";

import { DocumentsViewer } from "./DocumentsViewer";
import { SectionInfos, SectionsList } from "./SectionsList";

export interface DashboardSession extends SessionDto {
    selected?:boolean ;
    immutableDocuments?:boolean ;
}

export interface DashboardSection extends SectionDto {
    selectionInside:boolean ;
    sessions: DashboardSession[];
}

export type SessionFilter = (session: SessionDto, section:SectionCategory) => boolean ;
export interface DashboardProps {
    category?: SectionCategory ;
    role: SessionActorType|SessionActorType[] ;
    immutableDocuments?:Nullable<boolean> ;
    title: string ;
    emptyMessage?:string ;
    sectionInfos: (category: SectionCategory) => SectionInfos ;
    sessionFilter?:SessionFilter ;
    activateSearch?: boolean ;
    search?: string ;
    profileId: UUID ;
    acceptsSignature?:boolean ;
    updateDuration?:number ;
}

export const Dashboard = ({ profileId, role, title, sectionInfos, emptyMessage, immutableDocuments, search = '', category = undefined, activateSearch = false, sessionFilter = undefined, acceptsSignature = false, updateDuration = 30 }: DashboardProps) => {
    const [searchCriteria, setSearchCriteria] = useState(activateSearch && $trim(search).length?$trim(search):'');
    const { data, isError, isLoading } = useSections(profileId, role, SessionsQueryRestrictions.None, category, searchCriteria, true);
    const [sections, setSections] = useState<DashboardSection[]>([]);
    const [selectedSession, setSelectedSession] = useState<DashboardSession | undefined>(undefined);
    const forcedImmutableDocuments = $ok(immutableDocuments) ? immutableDocuments! : (category === 'draft' ? false : undefined) ;
    const { mutateAsync: deleteSession } = useDeleteSession();
    const [modalText, setModalText] = useState('');
    const [modalSessionID, setModalSessionID] = useState<Nullable<UUID>>(undefined) ;
    const toast = useToast();
    const queryClient = useQueryClient();
    const tabOrWindowVisible = usePageVisibilityChange();

    const location = useLocation() ;
    const searchParams = new URLSearchParams(location.search) ;

    const reloadSession = () => {
        optlog('Checking for modifications');
        if (!$ok(selectedSession)) {
            optlog('**** RESET QUERY INTERVAL ****')
            queryClient.invalidateQueries(SectionsListQueryID);
        }
    }

    const resetAndBack = () => {
        const [sects, sess] = visibleItems(data, forcedImmutableDocuments, undefined, sessionFilter, []) ;
        setSections(sects) ;
        setSelectedSession(sess);

        optlog(`--- Dashboard reseted with: ${sects.length} section(s)`, sess, $map(sects.first()?.sessions, s => `${s.title} [${s.selected}]`)) ;
    };

    const handleSelection = (sessionID:Nullable<UUID>) => {
        const [sects, sess] = visibleItems(data, forcedImmutableDocuments, sessionID, sessionFilter, []) ;
        setSections(sects) ;
        setSelectedSession(sess);

        optlog(`--- Dashboard did select session: ${sess?.apid}`) ;
    } ;

    const handleEdition = (sessionID:Nullable<UUID>) => {
        navigate(`/app/newSignature/${sessionID}`, { replace: true });
    }

    const handleDelete = (session:UUID|SessionDto) => {
        const s = typeof session === 'string' ? sessionWithSessionID(data, session) : session ;
        if ($ok(s)) {
            setModalText(`Voulez-vous vraiment supprimer le brouillon ${s!.title} ?`) ;
            setModalSessionID(s!.apid!) ;
        }
        else { toast(defaultErrorToast("Impossible de supprimer le brouillon: session introuvable.")) ; }
    }
    
    const handleExternalSignature = (session:SessionDto) => {
        resetAndBack() ;
    }

    const effectiveSessionDeletion = (sessionID:Nullable<UUID>) => {
        deleteSession(modalSessionID!) ;
        setModalSessionID(undefined) ;
        setModalText("") ;
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault();
        if (e.type === "submit") {
            setSearchCriteria(e.target[0].value);
        }
    };

    useEffect(() => {
        const sid = searchParams.get("sid") ;
        const [sects, sess] = visibleItems(data, forcedImmutableDocuments, $ok(sid) ? $UUID(sid) : undefined, sessionFilter, []) ;
        setSections(sects) ;
        setSelectedSession(sess);
        optlog(`--- Dashboard initialized with: ${sects.length} section(s) for profileID '${profileId}'`) ;
    }, [data, tabOrWindowVisible]);

    useEffect(() => {
        const interval = setInterval(reloadSession, 1000 * updateDuration);
        return () => clearInterval(interval);
    }, [selectedSession]);

    return (
        <>
            <PageShellWithThreeColumns
                centralContent={
                    <SectionsList
                        title={title}
                        sections={sections}
                        onSelect={handleSelection}
                        searchString={activateSearch?searchCriteria:undefined}
                        sectionInfos={sectionInfos}
                        onSearch={activateSearch?handleSearch:undefined}
                        onEdit={handleEdition}
                        onDelete={handleDelete}
                        emptyMessage={emptyMessage}
                        selectedSessionID={selectedSession?.apid}
                    />
                }
                rightContent={
                    trueOrEmptyCard(
                        !isError && !isLoading && $count(sections)>0,
                        $ok(selectedSession) ?
                            (<DocumentsViewer
                                profileId={profileId}
                                sessionId={selectedSession!.apid!}
                                acceptsSignature={!!acceptsSignature}
                                validityDate={$value(selectedSession?.validity?.end?.toLocaleString("%d/%m/%Y"), '')}
                                immutableDocuments={!!(selectedSession!.immutableDocuments)}
                                senderName={$ok(selectedSession!.expeditor) ? `${selectedSession!.expeditor!.firstName} ${selectedSession!.expeditor!.lastName.toUpperCase()}` : "Inconnu"}
                                signatureMode={selectedSession?.signatures?.first()?.signatureMode}
                                resetAndBack={resetAndBack}
                                deleteSession={() => handleDelete(selectedSession!)}
                                onExternalSignature={() => handleExternalSignature(selectedSession!)}
                                editSession={()=>handleEdition(selectedSession!.apid!)}
                            />) :
                            (<Text display="flex" flexDirection="column" justifyContent="center" height="100%" textAlign="center" color="lightgrey">
                                Sélectionnez une section pour afficher ses documents
                            </Text>)
                    )
                }
                isOpen={$ok(selectedSession)}
            />
            <ConfirmModal
                bodyText={modalText}
                isOpen={$length(modalText) > 0}
                onClose={() => setModalText('')}
                onAccept={() => effectiveSessionDeletion(modalSessionID)}
            />
        </>
    );
};

function sessionWithSessionID(source:Nullable<SectionDto[]>, sessionID?:Nullable<UUID>):DashboardSession|undefined {
    if (!$count(source) || !$ok(sessionID)) { return undefined ; }
    for (let section of source!) {
        const found = section.sessions?.find(session => session.apid === sessionID) ;
        if ($ok(found)) { return found ;}
    }
    return undefined ;
}

function visibleItems(source:Nullable<SectionDto[]>, forceImmutable:Nullable<boolean>, selectedSessionID:Nullable<UUID>, sessionFilter:Nullable<SessionFilter>, blacklisteds:UUID[]):[DashboardSection[], DashboardSession|undefined] {
    /*optlog('==========================') ;
    optlog('source', source) ;
    optlog('==========================') ; */
    if (!$count(source)) { return [[], undefined /*, []*/] ; }
    let theSelectedSession:DashboardSession|undefined = undefined ;
    
    const filter = $value(sessionFilter, (session:SessionDto, cat:SectionCategory) => true) ;
    const visibleSections = source!.map(section => {
        const immutable = $ok(forceImmutable) ? forceImmutable! : section.category !== 'draft' ;
        let selectionInside = false ;
        const visibleSessions = $map(section.sessions, session => {
            if (!filter(session, section.category) || ($ok(session.apid) && blacklisteds.includes(session.apid!))) { return null ; }
            const sessionSelected = session.apid === selectedSessionID ;
            selectionInside ||= sessionSelected ;
            const visibleSession = { ...session, selected:sessionSelected, immutableDocuments:immutable} ;
            if (visibleSession.selected) { 
                theSelectedSession = visibleSession ; 
            }
            return visibleSession ;
        }) ;
        return { ...section, selectionInside:selectionInside, sessions:visibleSessions } ; // we may not have any sessions in here
    }) ;
    return [visibleSections, theSelectedSession] ;
}

