import { UUID } from "foundation-ts/types";
import { ContactType } from "g1-commons/lib/doxecureTypes";

import { useQuery } from "react-query";

import { getContacts } from "../services/users.service";
import { ContactsQueryID } from "./hooksConstants";

const useContacts = (sourceUserID:UUID, contactType:ContactType) => useQuery([ContactsQueryID, contactType], () => getContacts(sourceUserID, contactType));

export default useContacts;
