import { Router } from "@reach/router";
import { PageProps } from "gatsby";

import React from "react";

import { config } from "../../config/config";

import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import PrivateRoute from "../../components/PrivateRoute";
import { ArchivedDocumentsView } from "../../containers/archivedDocuments/ArchivedDocumentsView";
import { CanceledDocumentsView } from "../../containers/canceled/CanceledDocumentsView";
import { ContactsMessagesView } from "../../containers/contacts/ContactsMessagesView";
import { DashboardView } from "../../containers/dashboard/DashboardView";
import { DraftView } from "../../containers/draft/DraftView";
import { ExpiredDocumentsView } from "../../containers/expired/ExpiredDocumentsView";
import { NewSessionView } from "../../containers/newSession/NewSessionView";
import { RecoverView } from "../../containers/recover/RecoverView";
import { RefusedDocumentsView } from "../../containers/refusedDocuments/RefusedDocumentsView";
import { SearchView } from "../../containers/search/SearchView";
import { SignedDocumentsView } from "../../containers/signedDocuments/SignedDocumentsView";
import { TemplateSessionsView } from "../../containers/templateSessions/TemplateSessionsView";
import { ToBeSignedDocumentsView } from "../../containers/toBeSignedDocuments/ToBeSignedDocumentsView";
import { UserProfileView } from "../../containers/userProfile/UserProfileView";
import { SessionSystemType } from "g1-commons/lib/doxecureModelTypes";

const App = (props: PageProps) => {
    // FIXME : real typage TS
    // and remove any everywhere
    return (
        <Router basepath="/app">
            <PrivateRoute
                path="/signatures"
                checkParams
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <DashboardView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/newSignature/:sessionId"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <NewSessionView systemType={SessionSystemType.Standard} />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/newSignature"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <NewSessionView systemType={SessionSystemType.Standard} />
                        </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/draft"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <DraftView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/tobeSignedDocuments"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <ToBeSignedDocumentsView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/signedDocuments"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <SignedDocumentsView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/expiredDocuments"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <ExpiredDocumentsView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/refusedDocuments"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <RefusedDocumentsView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/canceledDocuments"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <CanceledDocumentsView />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/archivedDocuments"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <ArchivedDocumentsView />
                    </AuthenticatedLayout>
                )}
            />        
            <PrivateRoute
                path="/contacts"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <ContactsMessagesView />
                    </AuthenticatedLayout>
                )}
            />
            {config.app.templateManagement &&
                <PrivateRoute
                    path="/templates"
                    component={() => (
                        <AuthenticatedLayout {...props as any}>
                            <TemplateSessionsView />
                        </AuthenticatedLayout>
                    )}
                />
            }
            {config.app.templateManagement &&
                <PrivateRoute
                    path="/newTemplate"
                    component={() => (
                        <AuthenticatedLayout {...props as any}>
                            <NewSessionView systemType={SessionSystemType.Template} />
                        </AuthenticatedLayout>
                    )}
                />
            }
            {config.app.templateManagement &&
                <PrivateRoute
                    path="/newTemplate/:sessionId"
                    component={() => (
                        <AuthenticatedLayout {...props as any}>
                            <NewSessionView systemType={SessionSystemType.Template} />
                        </AuthenticatedLayout>
                    )}
                />
            }
            <PrivateRoute
                path="/search"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <SearchView search={(props?.location?.state as any)?.search} />
                    </AuthenticatedLayout>
                )}
            />

            <PrivateRoute path="/recover" component={() => <RecoverView />} />

            <PrivateRoute
                path="/userProfile/account"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <UserProfileView />
                    </AuthenticatedLayout>
                )}
            />
        </Router>
    );
};

export default App;
