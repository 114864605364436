import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import React from "react";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";

import { AccountTab } from "./AccountTab";
import { ConsumptionsTab } from "./ConsumptionsTab";
import { SettingsTab } from "./SettingsTab";

export const UserProfileView = () => {

    return (
        <PageShellWithThreeColumns
            centralContent={
                <>
                    <ColumnTitle color={$g1Color('global.write')}>
                        <Text>Votre compte</Text>
                    </ColumnTitle>
                    <Tabs isFitted>
                        <TabList color={$g1Color('global.tabs.write')} backgroundColor={$g1Color('global.tabs.bg')}>
                            <Tab fontWeight="semibold">Compte</Tab>
                            <Tab fontWeight="semibold">Consommations</Tab>
                            <Tab fontWeight="semibold">Réglages</Tab>
                        </TabList>
                        <TabIndicator color={$g1Color('global.tabs.indicator.write')} backgroundColor={$g1Color('global.tabs.indicator.bg')} />
                        <TabPanels>
                            <TabPanel>
                                <AccountTab />
                            </TabPanel>
                            <TabPanel>
                                <ConsumptionsTab />
                            </TabPanel>
                            <TabPanel>
                                <SettingsTab />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </>
            }
            isOpen={false}
        />
    ) ;
};
