import { Checkbox, Flex, FormControl, FormLabel, HStack, Select, Text, useToast, VStack } from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import { $count, $email, $ok } from "foundation-ts/commons";
import { TSCountry } from "foundation-ts/tscountry";
import { $bool } from "foundation-ts/tsparser";
import { CorporationIdentifiersDictionary, ServiceSupplier, UserProfile } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { G1Button } from "../../components/G1Button";
import { G1Input } from "../../components/G1Input";
import { ErrorField } from "../../components/ErrorField";
import { SeaShellBox } from "../../components/SeaShellBox";
import { CorporationForm } from "../../components/CorporationForm/CorporationForm";

import { config } from "../../config/config";
import useAvailableCorporationIdentifiers from "../../hooks/useAvailableCorporationIdentifiers";
import useCountries from "../../hooks/useCountries";
import usePatchUser from "../../hooks/usePatchUser";
import useProfile from "../../hooks/useProfile";
import { authenticateWithProvider, deleteProviderChannel, getUserUuid } from "../../services/authentication.service";
import { defaultSuccessToast } from "../../utils";
import { defaultErrorToast } from "../../utils/toast";

export const AccountTab = () => {
    const toast = useToast() ;
    const { data: userProfile } = useProfile(getUserUuid(), 'AccountTab');
    const { data: countries } = useCountries() ;
    const { data: availableCorporationIdentifiers } = useAvailableCorporationIdentifiers(countries) ;
    const { mutateAsync: patchUser } = usePatchUser() ;
    const [validINChannel, setValidINChannel] = useState(false) ;
    const [isCompany, setIsCompany] = useState<boolean>($ok(userProfile?.vatNumber));
    const [isCorporate, setIsCorporate] = useState(false) ;
    const [corporationIdentifiersDict, setCorporationIdentifiersDict] = useState<CorporationIdentifiersDictionary>({}) ;
  
    const {
        getValues,
        register,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const handleChangeProfile = async (e: FormEvent<HTMLFormElement>) => { //SubmitHandler<any> = async (data: UserDto) => {
        try {
            e.preventDefault() ;
            const email = $email(getValues<string>("email")) ;
            await patchUser({
                apid: userProfile?.apid,
                firstName: getValues<string>("firstName"),
                lastName: getValues<string>("lastName"),
                email: $ok(email) ? email! : userProfile!.email
            }) ;
            const message = (email?.toLowerCase() !== userProfile?.email) ? `Un mail de validation de changement d'email vous a été envoyé sur l'adresse ${email}. Veuillez terminer la procédure pour que le changement soit pris en compte.` : "Profil mis à jour" ;
            toast(defaultSuccessToast(message)) ;
        } catch (error) {
            toast(defaultErrorToast("Problème rencontré lors de la mise à jour")) ;
        }
    } ;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value) ;
    }

    const handleDeleteProviderChannel = async () => {
        if (await deleteProviderChannel(userProfile?.apid!, ServiceSupplier.Chambersign)) {
            toast(defaultSuccessToast("Votre identité numérique a été supprimé de votre compte. Vous ne pourrez donc plus vous authentifier avec elle.")) ;
            setValidINChannel(false) ;
        } else {
            toast(defaultErrorToast("Impossible de supprimer votre identité numérique de votre compte.")) ;
        }
    }

    useEffect(() => { 
        const belongsToCorporate = userProfile?.userFlags?.profile === UserProfile.UserCorporate || userProfile?.userFlags?.profile === UserProfile.AdministratorCorporate ;
        setIsCorporate(belongsToCorporate) ;
        if (belongsToCorporate) {
            const corporation = userProfile.corporations?.first() ;
            setValue("organizationName", corporation?.name || "") ;
            setValue("organizationIdentifier", corporation?.corporationIdentifier || "") ;
            setValue("vatNumber", corporation?.vatNumber || "") ;
            setValue("streetNumber", corporation?.address?.streetNumber || "") ;
            setValue("street", corporation?.address?.street || "") ;
            setValue("streetComplement", corporation?.address?.complement || "") ;
            setValue("zipCode", corporation?.address?.zipCode || "") ;
            setValue("city", corporation?.address?.city || "") ;
            setValue("organizationCountry", corporation?.address?.country || countries?.first()?.code) ;
            const newCountry = TSCountry.country(corporation?.address?.country || countries?.first()?.code)?.alpha2Code ;
            if ($ok(newCountry)) {
                const newDict = availableCorporationIdentifiers?.get(newCountry!) ;
                if ($ok(newDict)) { 
                    setCorporationIdentifiersDict(newDict!) ;
                }
            }
        } else {
            setValue("organizationName", userProfile?.organizationName || "") ;
            setValue("vatNumber", userProfile?.vatNumber || "") ;
            setValue("companyAddressNo", userProfile?.organizationAddress?.streetNumber || "") ;
            setValue("companyAddressStreet", userProfile?.organizationAddress?.street || "") ;
            setValue("companyAddressComplement", userProfile?.organizationAddress?.complement || "") ;
            setValue("companyAddressZipCode", userProfile?.organizationAddress?.zipCode || "") ;
            setValue("companyAddressCity", userProfile?.organizationAddress?.city || "") ;
            setValue("companyCountry", userProfile?.organizationAddress?.country || countries?.first()?.code) ;
            setIsCompany($ok(userProfile?.vatNumber));
        }
        setValue("lastName", userProfile?.lastName || "") ;
        setValue("firstName", userProfile?.firstName || "") ;
        setValue("email", userProfile?.email.toString() || "") ;
        setValidINChannel($bool(userProfile?.userFlags?.hasValidINChannel)) ;
    }, [userProfile, availableCorporationIdentifiers]) ;

    return (
        <form noValidate onSubmit={handleChangeProfile}>
            <SeaShellBox component="form">
                <FormControl isRequired>
                    <FormLabel>Nom</FormLabel>
                    <G1Input component="form" registerInput={{ ...register("lastName", { required: true }) }} />
                    {errors.lastName && <ErrorField fieldName="nom" />}
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>Prénom</FormLabel>
                    <G1Input component="form" registerInput={{ ...register("firstName", { required: true }) }} />
                    {errors.firstName && <ErrorField fieldName="prénom" />}
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>Adresse email</FormLabel>
                    <G1Input component="form" type="email" registerInput={{ ...register("email", { required: true }) }} disabled={$count(userProfile?.corporations) > 0 || userProfile?.userFlags?.hasValidINChannel} />
                    {errors.email && <ErrorField fieldName="email" />}
                </FormControl>

                {isCorporate ? 
                    <CorporationForm required disabled showUniqueId label="Organisation" countries={countries} corporationIdentifiersDict={corporationIdentifiersDict} errors={errors} register={register} onChange={handleChange} />
                    : <>
                        <HStack as="button" type="button" marginY="5%" alignItems="center" onClick={() => { setIsCompany(!isCompany); trigger(); }}>
                            <Text>Je suis une entreprise</Text>
                            <Checkbox isChecked={isCompany}
                                bg={$g1Color('form.field.bg')}
                                color={$g1Color('form.field.write')}
                                borderColor={$g1Color('form.field.border')}
                                _hover={{ borderColor: $g1Color('form.field.hover.border') }}
                                _focus={{ borderColor: $g1Color('form.field.select.border') }}
                            />
                        </HStack>

                        <FormControl isRequired>
                            <FormLabel>Nom de l'entreprise</FormLabel>
                            <G1Input component="form" disabled={!isCompany} registerInput={{ ...register("organizationName", { required: true }) }} />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel> Numéro de TVA intracommunautaire</FormLabel>
                            <G1Input component="form" disabled={!isCompany} registerInput={{ ...register("vatNumber", { required: true }) }} />
                        </FormControl>

                        <FormControl>
                            <FormLabel> Adresse</FormLabel>
                            <VStack width="100%" spacing="3" align="stretch">
                                <HStack>
                                    <G1Input component="form" registerInput={{ ...register("companyAddressNo") }} placeholder="No" width="30%" disabled={!isCompany} />
                                    <G1Input component="form" registerInput={{ ...register("companyAddressStreet") }} placeholder="Rue" disabled={!isCompany} />
                                </HStack>

                                <G1Input component="form" registerInput={{ ...register("companyAddressComplement") }} placeholder="Complément d'adresse" marginBottom="2%" width="100%" disabled={!isCompany} />
                                <HStack>
                                    <G1Input component="form" registerInput={{ ...register("companyAddressZipCode") }} placeholder="Code postal" width="40%" maxLength={6} disabled={!isCompany} />
                                    <G1Input component="form" registerInput={{ ...register("companyAddressCity") }} placeholder="Ville" disabled={!isCompany} />
                                </HStack>
                                <Select
                                    {...register("companyCountry")}
                                    disabled={!isCompany}
                                    bg={$g1Color('form.field.bg')}
                                    color={$g1Color('form.field.write')}
                                    borderColor={$g1Color('form.field.border')}
                                    _hover={{ borderColor: $g1Color('form.field.hover.border') }}
                                    _focus={{ borderColor: $g1Color('form.field.select.border') }}
                                >
                                    {countries?.map((country) => (
                                        <option value={country.code} key={country.code}>
                                            {country.name}
                                        </option>
                                    ))}
                                </Select>
                            </VStack>
                        </FormControl>
                    </>
                }
            </SeaShellBox>
            <Flex direction="row" justifyContent="space-evenly" width="100%" style={{ marginTop: "10px" }}>
                <G1Button component="form" variant="important" title="Changer de mot de passe" whiteSpace="normal"
                    width={{ base: "100%", sm: "30%" }}
                    height="60px"
                    rounded="xl"
                    onClick={() => navigate("/app/recover")}
                />
                <G1Button component="form" variant="normal" title="Sauvegarder ma fiche" whiteSpace="normal" type="submit"
                    width={{ base: "100%", sm: "30%" }}
                    height="60px"
                    marginLeft={{ base: "unset", sm: "2%" }}
                    rounded="xl"
                />
                {$ok(config.chambersign.clientId) && !validINChannel &&
                    <G1Button component="form" variant="optional" title="Associer mon identité numérique" whiteSpace="normal"
                        width={{ base: "100%", sm: "30%" }}
                        height="60px"
                        marginLeft={{ base: "unset", sm: "2%" }}
                        rounded="xl"
                        onClick={() => authenticateWithProvider(ServiceSupplier.Chambersign, userProfile?.apid)}
                    />
                }
                {$ok(config.chambersign.clientId) && validINChannel &&
                    <G1Button component="form" variant="optional" title="Supprimer mon identité numérique" whiteSpace="normal"
                        width={{ base: "100%", sm: "30%" }}
                        height="60px"
                        marginLeft={{ base: "unset", sm: "2%" }}
                        rounded="xl"
                        onClick={handleDeleteProviderChannel}
                    />
                }
            </Flex>
        </form>
    );
} ;