import React from "react";

import { SessionActorType } from "g1-commons/lib/doxecureTypes";

import { getUserUuid } from "../../services";
import { okOrError, optlog, defaultSectionInformations } from "../../utils/functions";

import { Dashboard } from "../generics/Dashboard";

export const DraftView = () => {
    const profileId = getUserUuid() ;
    optlog(`+++ DraftView initialized with profile ID '${profileId}'`) ;

    return okOrError(
        profileId,
        (<Dashboard 
            title="Brouillons"
            emptyMessage="Pas de brouillons"
            category="draft"
            role={[SessionActorType.Creator, SessionActorType.Expeditor]} 
            sectionInfos={defaultSectionInformations} 
            profileId={profileId!}
        />),
        false,
        "Impossible d'afficher la liste des brouillons car l'identifiant de profil de la personne connectée n'a pas pu être récupéré.",
        "Connexion invalide"
    ) ;
};
