import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import React from "react";

import { DriveItemStructure } from "g1-commons/lib/doxecureTypes";
import { CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { getLabelForCloudProvider } from "../../utils/Provider";

interface DriveNavigatorProps {
    drive: CloudSubscriptionDto ;
    folders: DriveItemStructure[] ;
    onSelect: (folder: DriveItemStructure | undefined) => void ;
}

export const DriveNavigator = ({ drive, folders, onSelect }: DriveNavigatorProps) => {
    const supplierLabel = getLabelForCloudProvider(drive.supplier);
    return (
        <Breadcrumb separator={<Text color={$g1Color('global.icons.navigation.next.write')}> / </Text>}>
            <BreadcrumbItem>
                <BreadcrumbLink onClick={() => { onSelect(undefined); }}>
                    {supplierLabel}
                </BreadcrumbLink>
            </BreadcrumbItem>
            {folders.map((folder, i, array) => {
                return (
                    <BreadcrumbItem key={folder.id}>
                        {i === array.length - 1 ? (
                            <BreadcrumbLink isCurrentPage={true}>{folder.name}</BreadcrumbLink>
                        ) : (
                            <BreadcrumbLink onClick={() => onSelect(folder)}>{folder.name}</BreadcrumbLink>
                        )}
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    ) ;
} ;