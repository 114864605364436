import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
} from "@chakra-ui/react";
import React from "react";

import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { Provider } from "../../utils/Provider";
import { G1Button } from "../G1Button";

interface CloudBaseModalProps {
    isOpen: boolean;
    onClose: () => void;
    headerTxt: string;
    bodyTxt?: string;
    providerTxt?: string;
    providers: Array<Provider>;
    children?: JSX.Element;
    onSelect: (provider: ServiceSupplier) => void;
}
export const CloudBaseModal = (props: CloudBaseModalProps) => {
    const { isOpen, onClose, onSelect, providers, providerTxt, headerTxt, bodyTxt, children } = props;

    return (
        <Modal onClose={onClose} size="xs" isOpen={isOpen} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{headerTxt}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{bodyTxt}</ModalBody>
                <Box
                    w="100%"
                    mt="6"
                    display="grid"
                    gridGap="10px"
                    justifyContent="stretch"
                    margin="1em 0"
                    padding="0 10%"
                >
                    {providers.map((provider, i) => {
                        const providerText = providerTxt ? `${providerTxt} ${provider.label}` : provider.label;
                        return (
                            <Stack key={i} align="stretch">
                                <G1Button component="global" variant="optional" title={provider.slug ? providerText : provider.label} leftIcon={provider.icon} key={provider.slug} padding="unset" onClick={() => onSelect(provider.slug)} />
                                {children}
                            </Stack>
                        );
                    })}
                </Box>
                <ModalFooter margin="auto"></ModalFooter>
            </ModalContent>
        </Modal>
    );
};