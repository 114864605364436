import { HStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import React from "react";

import { DocumentDto } from "g1-commons/lib/doxecureClientTypes";

import { G1Icon } from "../../utils/icons";
import { CustomItemType } from "../../utils/TypesAndConstants";

import { CardDescription } from "./CardDescription";

interface DocumentCardProps {
    item: DocumentDto ;
    index?: number ;
    onClick: () => void ;
    itemStyle: CustomItemType ;
    displayIcon: boolean ;
}

export const DocumentCard = (props: DocumentCardProps) => {
    const {
        item: {fileName, },
        onClick,
        itemStyle,
        displayIcon,
    } = props ;

    // FIXME Should come from document ?
    const description='' ;
    return (
        <Box
            as="button"
            p="2"
            width="100%"
            minH="6em"
            borderTop={itemStyle.borderTop ?? "1px"}
            borderTopColor={itemStyle.borderTopColor ?? "inherit"}
            _hover={itemStyle.hover}
            _focus={itemStyle.select}
            onClick={onClick}
        >
            <Box width="100%" display="flex">
                <CardDescription width="100%" title={fileName} moreInfos={description} />
                {displayIcon && (
                    <HStack margin="1%" alignSelf="center">
                        <G1Icon.Right size="20px" cursor="pointer" />
                    </HStack>
                )}
            </Box>
        </Box>
    ) ;
} ;