import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { G1Button } from "../G1Button";

interface UploadFailModalProps {
    isOpen: boolean;
    onClose: () => void;
}
export const UploadFailModal = (props: UploadFailModalProps) => {
    const { isOpen, onClose } = props;
    return (
        <Modal onClose={onClose} size="xs" isOpen={isOpen} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>ATTENTION</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontWeight="bold" marginBottom="5%">
                        Impossible d'ajouter le document
                    </Text>
                    Le document que vous avez ajouté comporte un nombre de zones de signatures qui ne correspond pas au nombre de
                    signataires déclarés
                </ModalBody>
                <ModalFooter margin="auto">
                    <G1Button component="global" variant="normal" title="OK" width="100px" onClick={onClose} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
