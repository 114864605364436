import { Box, StyleProps, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import { UUID } from "foundation-ts/types";
import { $count, $ok } from "foundation-ts/commons";

import { DocumentDto, CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { DocumentCard } from "../../components/Cards/DocumentCard";
import { SeaShellBox } from "../../components/SeaShellBox";
import { SectionBox } from "../../components/SectionBox/SectionBox";
import { SectionBoxFooter } from "../../components/SectionBox/SectionBoxFooter";

import { config } from "../../config/config";
import { CustomItemType } from "../../utils/TypesAndConstants";

import { AddDocumentModal } from "./AddDocumentModal";

interface DocumentsToSignBoxProps {
    documents: DocumentDto[];
    drives: CloudSubscriptionDto[];
    onSelectDrive: (drive: CloudSubscriptionDto) => void;
    onClickUploadFiles: () => void;
    onViewDocument: (apid: UUID) => void;
}

const documentsSectionStyle: StyleProps = {
    bg: $g1Color('list.documents.bg'),
    color: $g1Color('list.documents.write'),
    fontWeight: "bold",
    overflow: "hidden",
    rounded: "xl",
    width: "100%",
} ;

const itemStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.documents.hover.bg'), color: $g1Color('list.documents.hover.write') },
    select: { bg: $g1Color('list.documents.select.bg'), color: $g1Color('list.documents.select.write') },
} ;

export const DocumentsToSignBox = ({
    documents,
    drives,
    onSelectDrive,
    onClickUploadFiles,
    onViewDocument,
}: DocumentsToSignBoxProps) => {
    const [openAddDocumentModal, setOpenAddDocumentModal] = useState<boolean>(false) ;
    const [forceReloadFooter, setForceReloadFooter] = useState<number>(0) ;

    const handleOpenAddDocumentModal = () => {
        setOpenAddDocumentModal(true) ;
    } ;

    const handleCloseAddDocumentModal = () => {
        setForceReloadFooter(forceReloadFooter + 1) ;
        setOpenAddDocumentModal(false) ;
    } ;

    const handleSelectDrive = (drive: CloudSubscriptionDto) => {
        setForceReloadFooter(forceReloadFooter + 1) ;
        setOpenAddDocumentModal(false) ;
        onSelectDrive(drive) ;
    } ;

    const handleClickUploadFiles = () => {
        setForceReloadFooter(forceReloadFooter + 1) ;
        setOpenAddDocumentModal(false) ;
        onClickUploadFiles() ;
    } ;

    const renderDocumentCard = (item: DocumentDto) => {
        // FIXME: should have something else to manage that here
        if (!$ok(item.apid) && !$ok(item.localUUID)) { throw '<DocumentsToSignBox>.renderDocumentCard(): document.apid and document.localUUID should not be both undefined'; }
        return (
            <DocumentCard
                key={item.apid || item.localUUID!}
                item={item}
                itemStyle={itemStyle}
                displayIcon
                onClick={() => onViewDocument(item.apid || item.localUUID!)}
            />
        ) ;
    } ;

   const renderAddDocumentButton = () => {
        return <SectionBoxFooter key={forceReloadFooter} component="documents" title="Ajouter un document" disabled={$count(documents) === config.session.maxNbDoc || exceedsMaxSessionSize(documents)} onClick={handleOpenAddDocumentModal} /> ;
    }

    return (
        <>
            <SeaShellBox component="form">
                <Text width="100%" fontWeight="bold">Documents à signer</Text>
                <Box mt="0">
                    <Text fontSize="small">{`Un dossier de signature peut contenir au maximum ${config.session.maxNbDoc} documents, chaque document ayant une taille maximale de ${config.session.maxDocSize/(1024*1024)}Mo.`}</Text>
                    <Text fontSize="small">{`Un dossier de signature ne peut pas dépasser ${config.session.maxSessionSize/(1024*1024)}Mo.`}</Text>
                </Box>
                <SectionBox
                    items={documents}
                    sectionStyle={documentsSectionStyle}
                    renderItem={renderDocumentCard}
                    renderFooter={renderAddDocumentButton}
                />
            </SeaShellBox>
            <AddDocumentModal
                drives={drives}
                isOpen={openAddDocumentModal}
                onClose={handleCloseAddDocumentModal}
                onSelectDrive={handleSelectDrive}
                onClickUploadFiles={handleClickUploadFiles}
            />
        </>
    ) ;
} ;

function exceedsMaxSessionSize(documents: DocumentDto[]) {
    let sessionSize = 0 ;
    for (let document of documents) {
        sessionSize += _realDocSize(document.size) ;
    }

    return sessionSize >= config.session.maxSessionSize ;
}

function _realDocSize(length: number): number {
    return (length+63)*41/30 ;
}