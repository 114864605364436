import { Box, StyleProps, Text } from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

import { $count, $isemail, $length, $ok, $value } from "foundation-ts/commons";
import { $ascii, $trim } from "foundation-ts/strings";
import { UUID } from "foundation-ts/types";

import { ContactDto, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { ContactType } from "g1-commons/lib/doxecureTypes";
import { compareUserBodies } from "g1-commons/lib/doxecureFunctions";

import { $g1Color } from "../@chakra-ui/gatsby-plugin/G1Style";

import useContacts from "../hooks/useContacts";
import { removeContact } from "../services/users.service";
import { SectionContacts } from "../utils/ReactTypesAndConstants";
import { CustomItemType } from "../utils/TypesAndConstants";

import { UserCard } from "./Cards/UserCard";
import { SearchField } from "./SearchField/SearchField";
import { SectionBox } from "./SectionBox/SectionBox";

interface ContactsListProps {
    connectedUserProfile: UserDto;
    additions: number;
    onItemClick?: (item: ContactDto) => void;
    onRemoveItemClick?: (item: ContactDto, remove: (item: ContactDto) => Promise<boolean>) => void;
    ordered?: boolean;
    blacklist?:Set<UUID>;
    contactType?: ContactType;
    search?: boolean;
}

const compareUsers = (a: ContactDto, b: ContactDto) => {
    return compareUserBodies(a.contact, b.contact) ;
};

const compareSection = (a: SectionContacts, b: SectionContacts) => {
    if (a.letter < b.letter) {
        return -1 ;
    } else if (a.letter === b.letter) {
        return 0 ;
    } else {
        return 1 ;
    }
};

const createSectionList = (contacts:ContactDto[]): SectionContacts[] =>
{
    const sections:SectionContacts[] = [] ;
    for (let i = 0 ; i < 26 ; i++) {
        sections[i] = { items:[], letter:String.fromCharCode(65+i) } ;
    } 
    sections[26] = { items:[], letter:'?'} ;

    const sortedContacts = contacts.sort(compareUsers) ;
    sortedContacts.forEach(c => {
        const name = $trim(c.contact.lastName)+$trim(c.contact.firstName) ;
        if (name.length) {
            let index = $ascii(name.charAt(0)).toUpperCase().charCodeAt(0)-65 ;
            if (index < 0 || index >= 26) { index = 26 ; }
            sections[index].items.push(c) ;
        }
    }) ;
    return sections.filter(s => s.items.length > 0) ;
} ;

const contactsSectionStyle:StyleProps = {
    bg: $g1Color('list.contacts.bg'),
    color: $g1Color('list.contacts.write'),
    fontWeight: "bold",
    overflow: "hidden",
    rounded: "xl",
    width: "100%",
};

const itemContactStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.contacts.hover.bg'), color: $g1Color('list.contacts.hover.write') },
    select: { bg: $g1Color('list.contacts.select.bg'), color: $g1Color('list.contacts.select.write') },
}

export const ContactsList = (props: ContactsListProps) => {
    const { ordered, contactType, connectedUserProfile, blacklist, search, onItemClick, onRemoveItemClick } = props ;
    const { data: contacts } = useContacts(connectedUserProfile.apid!, $value(contactType,ContactType.All)) ;
    const [filteredContacts, setFilteredContacts] = useState<ContactDto[]>([]) ;
    const [contactsSections, setContactsSections] = useState<SectionContacts[]>([]) ;
    const [searchValue, setSearchValue] = useState<string>("") ;
    const searchFormRef = useRef<HTMLFormElement>(null) ;

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault() ;
        if ((e.type === "submit") && $ok(contacts)) {
            let contactsList: ContactDto[] = [];
            if ($length(searchValue.trim()) === 0) {
                contactsList = $ok(blacklist) ? contacts!.filter(c => $ok(c.contact.apid) && !blacklist!.has(c.contact.apid!)) : contacts!
            } else {
                contactsList = filteredContacts!.filter(c => $isemail(searchValue.trim()) ? c.contact.email.toLowerCase() == searchValue.trim().toLowerCase() : c.contact.lastName.toLowerCase().includes(searchValue.trim().toLowerCase())) ;
            }
            setFilteredContacts(contactsList);
            setContactsSections($count(contactsList) ? createSectionList(contactsList).sort(compareSection) : []);
        }
    } ;

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value) ;
    } ;

    const handleRemoveContact = async (contact: ContactDto): Promise<boolean> => {
        const removed = await removeContact(connectedUserProfile.apid!, contact.apid!) ;
        if (removed) {
            const index = contacts!.findIndex(c => c.apid === contact.apid) ;
            if (index !== -1) {
                contacts?.splice(index, 1) ;
                setFilteredContacts(contacts!) ;
                setContactsSections(createSectionList(contacts!).sort(compareSection)) ;
            }
        }
        return removed ;
    } ;

    const renderContactsBox = (item: ContactDto) => {
        return <UserCard key={item.apid} item={item.contact} itemStyle={itemContactStyle} displayIcon={$ok(onRemoveItemClick)} onItemClick={$ok(onItemClick) ? () => onItemClick!(item) : undefined} onRemoveItemClick={$ok(onRemoveItemClick) ? () => onRemoveItemClick!(item, handleRemoveContact) : undefined} /> ;
    } ;

    useEffect(() => {
        if (searchValue.length === 0) {
            searchFormRef.current?.requestSubmit() ;
        }
    }, [searchValue]) ;

    useEffect(() => {
        if ($ok(contacts)) {
            const contactsList = $ok(blacklist) ? contacts!.filter(c => $ok(c.contact.apid) && !blacklist!.has(c.contact.apid!)) : contacts! ;
            setFilteredContacts(contactsList);
            setContactsSections($count(contactsList) ? createSectionList(contactsList).sort(compareSection) : []);
        }
    }, [contacts]) ;

    return (
        <>
            {search &&  (
                    <form ref={searchFormRef} onSubmit={handleSubmit}>
                        <SearchField component="global" h="30px" mb="2%" placeHolder="Nom ou Adresse mail" onChange={handleSearchInputChange} />
                    </form>
                )
            }
            {!!contactsSections.length && (
                <>
                    {ordered ? (
                        contactsSections.map((section, index) => {
                            return (
                                <Box width="100%" key={index}>
                                    <Text fontWeight="bold" marginBottom="5%">{section.letter}</Text>
                                    <SectionBox items={section.items} sectionStyle={contactsSectionStyle} renderItem={renderContactsBox} />
                                </Box>
                            );
                        })
                    ) : (
                        <SectionBox items={filteredContacts} sectionStyle={contactsSectionStyle} renderItem={renderContactsBox} />
                    )}
                </>
            )}
        </>
    ) ;
} ;