import { Text } from "@chakra-ui/layout";
import {
    Center,
    Flex,
    FormControl,
    FormLabel,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Nullable, UUID } from "foundation-ts/types";
import { $email, $ok } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { ContactType, UserProfile } from "g1-commons/lib/doxecureTypes";
import { ContactDto, CorporationDto, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { userName } from "g1-commons/lib/doxecureFunctions";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { ContactsList } from "../../components/ContactsList";
import { ErrorField } from "../../components/ErrorField";
import { G1Button } from "../../components/G1Button";
import { G1Input } from "../../components/G1Input";
import { SeaShellBox } from "../../components/SeaShellBox";
import { SearchUsers } from "../../components/admin/SearchUsers";
import { UserParameters } from "../../components/UserInfos/UserInfos";

import { addUserContact } from "../../services/users.service";
import { MandatoryEmail, NeededPersonRole } from "../../utils/TypesAndConstants";
import { defaultSuccessToast } from "../../utils";
import { defaultErrorToast } from "../../utils/toast";
import { trueOrEmptyCard, validateEmail } from "../../utils/functions";
import { G1Icon } from "../../utils/icons";
import { getCorporationUsers } from "../../services/corporations.service";

interface PersonPanelProps {
    profileId: UUID;
    connectedUserProfile: UserDto;
    canaddme?: boolean;
    role: NeededPersonRole;
    blacklist?: Set<UUID>;
    onSelectPersonItemAs: (item: PersonItem, role: NeededPersonRole) => void;
    onBack: () => void;
}
export interface PersonItem {
    contactID?: UUID;
    person: UserDto;
    isConnectedUser?: boolean;
};

export type NewPersonFormValues = {
    email: string;
    lastName: string;
    firstName: string;
};

export const PersonPanel = ({ profileId, role, connectedUserProfile, onSelectPersonItemAs, onBack, blacklist, canaddme = false }: PersonPanelProps) => {
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onSubmit",
        defaultValues: { lastName: "", firstName: "", email: "" },
    });
    const [adds, setAdds] = useState(0) ;
    const [corporateUsers, setCorporateUsers] = useState<Nullable<UserParameters[]>>(null) ;
    const [workedCorporateUsers, setWorkedCorporateUsers] = useState<Nullable<UserParameters[]>>(null) ;
    const isCorporate = connectedUserProfile.userFlags?.profile === UserProfile.AdministratorCorporate || connectedUserProfile.userFlags?.profile === UserProfile.UserCorporate ;
    const isSigner = role === NeededPersonRole.Signer ;
    const addConnectedUserAsTitle = `M'ajouter ${isSigner ? "aux signataires" : "comme expéditeur"} ` ;
    const usualContactsType = isSigner ? ContactType.UsualSigner : ContactType.UsualExpeditor ;
    const toast = useToast() ;

    const handleSelectPersonItem = (item: PersonItem) => {
        onSelectPersonItemAs(item!, role);
    } ;

    const handleSelectContactItem = (item: ContactDto) => {
        handleSelectPersonItem({ person: item.contact, contactID: item.apid });
    } ;

    const handleSelectCollaboratorItem = (userId: UUID) => {
        const user = corporateUsers!.filter(user => user.apid === userId).first() ;
        if ($ok(user)) {
            handleSelectPersonItem({ person: user!, contactID: userId! }) ;
        }
    } ;

    const handleSearchCorporationUser = async (search?: Nullable<string>): Promise<void> => {
        const users = $ok(search) ? corporateUsers?.filter(userDto => userDto.lastName.includes(search!.toUpperCase()) || userDto.email === search?.toLocaleLowerCase()) : corporateUsers ;
        setWorkedCorporateUsers(users?.map(userDto => { return {
            firstName: userDto.firstName,
            lastName: userDto.lastName,
            email: userDto.email,
            apid: userDto.apid,
            userFlags: userDto.userFlags
        }})) ;
    } ;

    const onSubmit: SubmitHandler<NewPersonFormValues> = async (values): Promise<void> => {
        try {
            const mail = $email(values.email.toLowerCase());
            if (!$ok(mail)) { throw 'Bad email'; }
            let newContact = await addUserContact(profileId, { firstName: values.firstName, lastName: values.lastName.toUpperCase(), email: mail! });
            if (!$ok(newContact) || !$ok(newContact.apid)) {
                throw 'Bad contact creation';
            }
            toast(defaultSuccessToast(`${userName(newContact.contact)} ajouté.`));
            setAdds(adds+1) ;
            reset();
            onSelectPersonItemAs({ person: newContact.contact, contactID: newContact.apid! }, role);
        }
        catch (error) {
            toast(defaultErrorToast("Les informations renseignées ne sont pas valides."));
        }
    } ;

    useEffect(() => {
        const setCorporationUsersList = async (connectedUserId: UUID, corporations: CorporationDto[]) => {
            const users: UserParameters[] = [] ;
            for (let corporation of corporations) {
                const usersDto = await getCorporationUsers(corporation.apid) ;
                if ($ok(usersDto)) {
                    users.push(...usersDto!.filter(userDto => (userDto.apid !== connectedUserId) && !blacklist?.has(userDto.apid!)).map(userDto => {
                            return {
                                firstName: userDto.firstName,
                                lastName: userDto.lastName,
                                email: userDto.email,
                                apid: userDto.apid,
                                userFlags: userDto.userFlags
                            }}
                    )) ;
                }
            }
            setWorkedCorporateUsers(users) ;
            setCorporateUsers(users) ;
        }

        if (isCorporate) {
            setCorporationUsersList(connectedUserProfile.apid!, connectedUserProfile.corporations!) ;
        } else {
            setWorkedCorporateUsers([]) ;
            setCorporateUsers([]) ;
        }
    }, [isCorporate]) ;

    return (
        <>
            <ColumnTitle color={$g1Color('global.write')}>
                <Flex alignItems="center" as="button" onClick={onBack} width="100%">
                    <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                    <Text>Mettre à la signature</Text>
                </Flex>
            </ColumnTitle>
            <Tabs isFitted>
                <TabList backgroundColor={$g1Color('global.tabs.bg')} color={$g1Color('global.tabs.write')}>
                    <Tab fontWeight="semibold">{isSigner ? "Signataires" : "Expéditeurs"} Usuels</Tab>
                    <Tab fontWeight="semibold">Tous</Tab>
                    { isCorporate && <Tab fontWeight="semibold">Collaborateurs</Tab> }
                    <Tab fontWeight="semibold">Ajouter</Tab>
                </TabList>
                <TabIndicator color={$g1Color('global.tabs.indicator.write')} backgroundColor={$g1Color('global.tabs.indicator.bg')} />
                <TabPanels>
                    <TabPanel>
                        { trueOrEmptyCard(canaddme,(<G1Button component="form" variant="important" title={addConnectedUserAsTitle} width="100%" mb="10%" height="70px" justifyContent="center" fontSize="lg" onClick={() => { handleSelectPersonItem({ person: connectedUserProfile!, isConnectedUser: true }); }} />)) }
                        <ContactsList connectedUserProfile={connectedUserProfile!} blacklist={blacklist} contactType={usualContactsType} onItemClick={handleSelectContactItem} additions={adds} />
                    </TabPanel>
                    <TabPanel>
                        { trueOrEmptyCard(canaddme,(<G1Button component="form" variant="important" title={addConnectedUserAsTitle} width="100%" mb="10%" height="70px" justifyContent="center" fontSize="lg" onClick={() => { handleSelectPersonItem({ person: connectedUserProfile!, isConnectedUser: true }); }} />)) }
                        <ContactsList connectedUserProfile={connectedUserProfile!} blacklist={blacklist} contactType={ContactType.All} onItemClick={handleSelectContactItem} additions={adds} search ordered />
                    </TabPanel>
                    { isCorporate &&
                        <TabPanel>
                            <SearchUsers users={workedCorporateUsers || []} onSearch={handleSearchCorporationUser} onItemClick={handleSelectCollaboratorItem} />
                        </TabPanel>
                    }
                    <TabPanel>
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <SeaShellBox component="form" spacing="5">
                                <FormControl isRequired>
                                    <FormLabel>Nom</FormLabel>
                                    <G1Input component="form" registerInput={{ ...register("lastName", { required: "Nom obligatoire" }) }} textTransform="uppercase" />
                                    {errors.name && <ErrorField fieldName="nom" />}
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Prénom</FormLabel>
                                    <G1Input component="form" registerInput={{ ...register("firstName", { required: "Prénom obligatoire" }) }} textTransform="capitalize" />
                                    {errors.firstName && <ErrorField fieldName="prénom" />}
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Adresse email</FormLabel>
                                    <G1Input
                                        component="form"
                                        type="email"
                                        registerInput={{ ...register("email", {
                                            validate: value => validateEmail(value, $trim(connectedUserProfile?.email).length ? [$trim(connectedUserProfile!.email)] : []),
                                            required: MandatoryEmail
                                        }) }}
                                        textTransform="lowercase"
                                    />
                                    {$ok(errors?.email?.message) && <ErrorField fieldName="email" error={`${errors!.email!.message!}`} />}
                                </FormControl>
                            </SeaShellBox>
                            <G1Button component="form" variant="important" title={`Créer un ${isSigner ? 'nouveau signataire' : 'nouvel expéditeur'}`} type="submit" marginTop="5%" width="100%" height="70px" rounded="xl" justifyContent="center" fontSize="lg" />
                        </form>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    ) ;
} ;