import { SectionCategory, SessionActorType } from "g1-commons/lib/doxecureTypes";
import { SessionsQueryRestrictions } from "g1-commons/lib/doxecureClientTypes";

import { useQuery } from "react-query";

import { getSectionsForUser } from "../services/session.service";
import { UUID } from "foundation-ts/types";
import { SectionsListQueryID } from "./hooksConstants";

const useSections = (
    userID:UUID, 
    role:SessionActorType|SessionActorType[], 
    restrictions?:SessionsQueryRestrictions, 
    category?: SectionCategory, 
    searchString?: string,
    nocookie?: boolean) =>
    
    useQuery([SectionsListQueryID, userID, role, category,searchString], () => getSectionsForUser(userID, role, restrictions, category, searchString, nocookie));

export default useSections;
