import React from "react";

import { SessionActorType } from "g1-commons/lib/doxecureTypes";

import { getUserUuid } from "../../services";
import { okOrError, optlog, defaultSectionInformations } from "../../utils/functions";

import { Dashboard } from "../generics/Dashboard";

export const ToBeSignedDocumentsView = () => {
    const profileId = getUserUuid() ;
    optlog(`+++ ToBeSignedDocumentsView initialized with profile ID '${profileId}'`) ;
    
    return okOrError(
        profileId,
        (<Dashboard 
            title="Documents en attente de signature"
            emptyMessage="Pas de documents en attente de signature"
            category="to-be-signed"
            role={[SessionActorType.Creator, SessionActorType.Expeditor, SessionActorType.Signer]} 
            sectionInfos={defaultSectionInformations} 
            profileId={profileId!}
        />),
        false,
        "Impossible d'afficher la liste des documents en attente de signature car l'identifiant de profil de la personne connectée n'a pas pu être récupéré.",
        "Connexion invalide"
    ) ;
};
