import { useQuery } from "react-query";

import { $ok } from "foundation-ts/commons";
import { TSCountry } from "foundation-ts/tscountry";
import { country } from "foundation-ts/types";
import { G1Country } from "g1-commons/lib/doxecureLocales";

import apiClient from "../services/apiClient";

import { AvailableCorporationIdentifiersID } from "./hooksConstants";
import { CorporationIdentifiersDictionary } from "g1-commons/lib/doxecureTypes";

const useAvailableCorporationIdentifiers = (countries?: G1Country[]) =>  useQuery([AvailableCorporationIdentifiersID], async () => {
    const map: Map<country, CorporationIdentifiersDictionary> = new Map() ;
    if ($ok(countries)) { 
        for (let g1Country of countries!) {
            const country = TSCountry.country(g1Country.code)!.alpha2Code ;
            const [corporationIdentifiersDict, _status] = await apiClient.getAvailableCorporationIdentifiers(country) ;
            if ($ok(corporationIdentifiersDict)) {
                map.set(country, corporationIdentifiersDict!) ;
            }
        }
    }
    return map ;
}) ;

export default useAvailableCorporationIdentifiers ;