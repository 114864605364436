import { Box, Flex, Text } from "@chakra-ui/layout";
import React from "react";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style" ;

import { G1Icon } from "../../utils/icons";
import { ListComponent } from "../../utils/TypesAndConstants";

interface SectionBoxFooterProps {
    component: ListComponent ;
    title: string ;
    disabled?: boolean ;
    onClick: () => void ;
}

export const SectionBoxFooter = (props: SectionBoxFooterProps) => {
    const { component, title, disabled, onClick } = props ;
    const comp = (component === "") ? "" : `.${component}`;

    return (
        <Flex
            width="100%"
            as="button"
            px="4"
            py="2"
            minH="6em"
            alignItems="center"
            borderTop="1px"
            borderBottomColor={$g1Color('list.spacer')}
            bg={$g1Color(`list${comp}.bg`)}
            color={$g1Color(`list${comp}.write`)}
            _hover={{ bg: disabled ? $g1Color(`list${comp}.bg`) : $g1Color(`list${comp}.hover.bg`), color: disabled ? $g1Color(`list${comp}.write`) : $g1Color(`list${comp}.hover.write`) }}
            _focus={{ bg: disabled ? $g1Color(`list${comp}.bg`) : $g1Color(`list${comp}.select.bg`), color: disabled ? $g1Color(`list${comp}.write`) : $g1Color(`list${comp}.select.write`) }}
            opacity={disabled ? 0.5: 1}
            cursor={disabled ? "default" : "pointer"}
            disabled={disabled}
            onClick={() => onClick()}
        >
            <Box margin="0 7% 0 2%">
                <G1Icon.Plus size="30px" />
            </Box>
            <Text fontWeight="bold">
                {title}
            </Text>
        </Flex>
    ) ;
} ;