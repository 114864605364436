import { Box } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import React from "react";

import { $ok } from "foundation-ts/commons";
import { CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes";

import { G1Icon } from "../../utils/icons";
import { getIconForCloudProvider, getLabelForCloudProvider } from "../../utils/Provider";
import { CustomItemType, StandarDateFormat } from '../../utils/TypesAndConstants';

import { CardDescription } from "./CardDescription";

interface CloudCardProps {
    item: CloudSubscriptionDto;
    onClick: () => void;
    itemStyle: CustomItemType;
}
export const CloudCard = (props: CloudCardProps) => {
    const {
        itemStyle,
        item: { identifier, validity, supplier },
        onClick,
    } = props;

    const providerIcon = getIconForCloudProvider(supplier) ;
    const providerLabel = getLabelForCloudProvider(supplier) ;
    const description = $ok(validity) ? `${providerLabel} actif depuis le ${validity.start.toString(StandarDateFormat)}` : '' ;

    return (
        <Flex
            p="2"
            width="100%"
            minH="6em"
            borderTop={itemStyle.borderTop ?? "1px"}
            borderTopColor={itemStyle.borderTopColor ?? "inherit"}
            _hover={itemStyle.hover}
            _focus={itemStyle.select}
            cursor="pointer"
            onClick={onClick}
        >
            <CardDescription
                title={identifier}
                moreInfos={description}
                icon={providerIcon}
                width="100%"
                textOverflow="ellipsis"
            />
            <Box margin="5%" alignSelf="center" color={itemStyle.iconColor ?? "inherit"}>
                <G1Icon.Minus size="20px" cursor="pointer" onClick={onClick} />
            </Box>
        </Flex>
    ) ;
} ;