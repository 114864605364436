import { useMutation, useQueryClient } from "react-query";

import { $ok } from "foundation-ts/commons";
import { Nullable, UUID } from "foundation-ts/types";
import { SessionDto } from "g1-commons/lib/doxecureClientTypes";

import { createSessionForUser, patchSessionForUser } from "../services/session.service";
import { SectionsListQueryID } from "./hooksConstants";

const useCreateOrUpdateDraftSession = (userID:Nullable<UUID>) => {
    const queryClient = useQueryClient();
    return useMutation(
        ( session : SessionDto) => ($ok(session.apid) ? patchSessionForUser(session) : createSessionForUser(session, userID)),
        {
            onSuccess: () => {
                // A la mise à jour d'une session, on récupère les sessions
                queryClient.invalidateQueries(SectionsListQueryID);
            },
        }
    );
};

export default useCreateOrUpdateDraftSession;