import { Box, Button, Center, Flex } from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import { $ok } from "foundation-ts/commons";
import { CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes";
import { DriveItemStructure } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { DriveExplorer } from "../../components/DriveExplorer/DriveExplorer";
import { DriveNavigator } from "../../components/DriveExplorer/DriveNavigator";
import { G1Icon } from "../../utils/icons";

interface DriveExplorerPanelProps {
    drive: CloudSubscriptionDto;
    onBack: () => void;
    onSelectFile: (drive: CloudSubscriptionDto, file: DriveItemStructure) => void;
}

export const DriveExplorerPanel = ({ drive, onBack, onSelectFile }: DriveExplorerPanelProps) => {
    const [folders, setFolders] = useState<DriveItemStructure[]>([]);
    const [currentFolder, setCurrentFolder] = useState<string>(""); // Root folder of the drive by default
    const supplier = useRef(drive.supplier) ;
    // Avoid to call backend with wrong parameters when changing drive type (new drive and old drive selected folder)
    if (supplier.current !== drive.supplier) {
        supplier.current = drive.supplier ;
        setCurrentFolder("") ;
        setFolders([]) ;
    }

    const handleSelectFolder = (folder: DriveItemStructure) => {
        setCurrentFolder(folder.id);
        setFolders([...folders, folder]);
    };

    const handleSelectFile = (file: DriveItemStructure) => {
        onSelectFile(drive, file);
    };

    const handleResetToFolder = (folder: DriveItemStructure | undefined) => {  
        const index = $ok(folder) ? folders.findIndex((entry) => entry.id === folder!.id) : -1 ;
        if (index >= 0) {
            setFolders(folders.slice(0, index + 1)); 
            setCurrentFolder(folder!.id);
        }
        else {
            setCurrentFolder("");
            setFolders([]);
        }
    };

    return (
        <>
            <ColumnTitle color={$g1Color('global.write')}>
                <Flex alignItems="center" width="100%">
                    <Button onClick={onBack} backgroundColor="transparent">
                        <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                    </Button>
                    <DriveNavigator drive={drive} folders={folders} onSelect={handleResetToFolder} />
                </Flex>
            </ColumnTitle>
            <Box p="2%" flex="1">
                <Box mt="5" h="full" width="100%">
                    <Box padding="unset">
                        <DriveExplorer
                            drive={drive}
                            folder={currentFolder}
                            onSelectFolder={handleSelectFolder}
                            onSelectFile={handleSelectFile}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
