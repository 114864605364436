import React from "react";

import { $ok } from "foundation-ts/commons";
import { SessionDto } from "g1-commons/lib/doxecureClientTypes";
import { SectionCategory, SessionActorType } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { Dashboard, SessionFilter } from "../generics/Dashboard";
import { SectionInfos } from "../generics/SectionsList";
import { getUserUuid } from "../../services";
import { sectionMap } from "../../utils/TypesAndConstants";
import { canSessionBeSigned, okOrError, optlog } from "../../utils/functions";

// @ts-ignore
const tosignSectionInformations = (category?: SectionCategory): SectionInfos => {
    const component = `list.tosign.${$ok(category) && (category === "urgent") ? "urgent." : ""}` ;
    const header = $ok(category) ? sectionMap.get(category!) : undefined ;

    return {
        title:header?.title || `*** DEBUG SECTION ${category} ***`,
        description:header?.description || `*** SECTION DESCRIPTION ***`,
        style: {
            bg: $g1Color(`${component}bg`),
            color: $g1Color(`${component}write`),
            borderTopColor: $g1Color('list.spacer'),
            rounded: { base: "lg" },
        },
        itemStyle: {
            borderTop: "1px",
            borderTopColor: $g1Color('list.spacer'),
            hover: { bg: $g1Color(`${component}hover.bg`), color: $g1Color(`${component}hover.write`) },
            select: { bg: $g1Color(`${component}select.bg`), color: $g1Color(`${component}select.write`) },
        }
    } ;
}

export const DashboardView = () => {
    const profileId = getUserUuid() ;
    optlog(`+++ DashboardView initialized with profile ID '${profileId}'`) ;
    const sessionFilter:SessionFilter = (session:SessionDto, _:SectionCategory):boolean => !!canSessionBeSigned(session, profileId) ;

    return okOrError(
        profileId,
        (<Dashboard 
            // we don't pass any category here, which means that we want all the
            // to-be-approved, to-be-signed, urgent and expired sessions as a signer
            title="Mon Parapheur"
            emptyMessage="Pas de documents à signer ou approuver"
            role={SessionActorType.Signer} 
            sectionInfos={tosignSectionInformations}
            profileId={profileId!} 
            immutableDocuments={true}
            acceptsSignature={true}
            sessionFilter={sessionFilter}
        />),
        false,
        "Impossible d'afficher le parapheur car l'identifiant de profil de la personne connectée n'a pas pu être récupéré.",
        "Connexion invalide"
    ) ;
};
