import { $isuuid, $length, $totype } from "foundation-ts/commons";
import { Resp, RespType, Verb } from "foundation-ts/tsrequest";
import { Nullable, UUID } from "foundation-ts/types";

import { CloudSubscriptionDto } from "g1-commons/lib/doxecureClientTypes";
import { DriveItemStructure, ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import apiClient, { assertResponse } from "./apiClient";
import { TSError } from "foundation-ts/tserrors";

export const connectUserToDrive = async (userID: Nullable<UUID>, provider: ServiceSupplier, redirectUri: string): Promise<string> => {
    if (!$isuuid(userID)) { throw 'connectUserToDrive(): unspecified or malformed userID'; }
    const [ret, status] = await apiClient.request(`user/${userID}/cloud/${provider}/connect?redirectUri=${redirectUri}`, Verb.Get);
    if (status !== Resp.OK) {
        TSError.throw(`Erreur lors de la connexion au cloud`) ;
    }
    return assertResponse($totype(ret), status);
};

export const getDriveFolderContent = async (userID: Nullable<UUID>, userChannelID: Nullable<UUID>, folderID: Nullable<string>): Promise<DriveItemStructure[]> => {
    if (!$isuuid(userID)) { throw 'getDriveFolderContent(): unspecified or malformed userID'; }
    if (!$isuuid(userChannelID)) { throw 'getDriveFolderContent(): unspecified or malformed userChannelID'; }
//    if (!$length(folderID)) { throw 'getDriveFolderContent(): unspecified folderID'; }
    const [ret, status] = await apiClient.request(`/user/${userID}/cloud/${userChannelID}/list/${folderID}`, Verb.Get);
    if (status !== Resp.OK) {
        TSError.throw(`Erreur lors de la déconnexion au cloud`) ;
    }
    return assertResponse($totype<DriveItemStructure[]>(ret), status);
};

export const downloadDriveFile = async (userID: Nullable<UUID>, userChannelID: Nullable<UUID>, fileID: Nullable<string>): Promise<Buffer> => {
    if (!$isuuid(userID)) { throw 'downloadDriveFile(): unspecified or malformed userID'; }
    if (!$isuuid(userChannelID)) { throw 'downloadDriveFile(): unspecified or malformed userChannelID'; }
    if (!$length(fileID)) { throw 'downloadDriveFile(): unspecified fileID'; }
    const [ret, status] = await apiClient.request(`/user/${userID}/cloud/${userChannelID}/download/${fileID}`, Verb.Get, RespType.Buffer);
    return assertResponse($totype(ret), status);
}

export const deconnectDrive = async (userID: Nullable<UUID>, userChannelID: Nullable<UUID>): Promise<CloudSubscriptionDto[]> => {
    if (!$isuuid(userID)) { throw 'deconnectDrive(): unspecified or malformed userID'; }
    if (!$isuuid(userChannelID)) { throw 'deconnectDrive(): unspecified or malformed userChannelID'; }
    const [ret, status] = await apiClient.request(`/user/${userID}/cloud/${userChannelID}`, Verb.Delete);
    return assertResponse($totype<CloudSubscriptionDto[]>(ret), status);
};
