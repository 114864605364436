import { VStack, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";

import { $ok } from "foundation-ts/commons";
import { ContactDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { ContactsList } from "../../components/ContactsList";
import { AsyncConfirmModal, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";
import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";

import useProfile from "../../hooks/useProfile";
import { getUserUuid } from "../../services";
import { optlog, trueOrEmptyCard } from "../../utils/functions";
import { defaultErrorToast, defaultSuccessToast } from "../../utils/toast";

export const ContactsMessagesView = () => {
    const profileId = getUserUuid();
    const { data: userProfile, isError: profileError, isLoading: profileLoading } = useProfile(profileId, 'ContactsMessagesView') ;
    const [modal, setModal] = useState(DefaultAsyncConfirmModalState) ;
    const toast = useToast() ;
    optlog(`+++ ContactsMessagesView initialized with profile ID '${profileId}'`) ;

    const handleRemoveContact = async (contact: ContactDto, remove: (contact: ContactDto) => Promise<boolean>) => {
        const contactName = `${contact.contact.firstName.capitalize()} ${contact.contact.lastName.toUpperCase()}` ;
        setModal({
            message: `Êtes-vous sûr de vouloir supprimer ${contactName} de votre liste de contacts ?`,
            onClose: async () => setModal(DefaultAsyncConfirmModalState),
            onAccept: async () => {
                try {
                    if (await remove(contact)) {
                        toast(defaultSuccessToast(`${contactName} ne fait plus partie de votre liste de contacts`)) ;
                    } else {
                        toast(defaultErrorToast(`Impossible de supprimer ${contactName} de votre liste de contacts`)) ;
                    }
                } catch (e) {
                    toast(defaultErrorToast((e as Error).message)) ;
                }
                setModal(DefaultAsyncConfirmModalState);
            },
        }) ;
    } ;

    return trueOrEmptyCard(
        $ok(profileId) && $ok(userProfile),
        <PageShellWithThreeColumns
            centralContent={
                <>
                    <ColumnTitle color={$g1Color('global.write')}>
                        <Text>Contacts</Text>
                    </ColumnTitle>
                    <VStack align="stretch" spacing="5">
                        <ContactsList connectedUserProfile={userProfile!} additions={0} ordered onRemoveItemClick={handleRemoveContact} />
                    </VStack>
                    <AsyncConfirmModal message={modal.message} onAccept={modal.onAccept} onClose={modal.onClose} />
                </>
            }
        />,
        profileLoading,
        profileError || !$ok(profileId) || !$ok(userProfile),
        "Impossible de récupérer le profil de l'utilisateur",
        "Connexion invalide",
        "Chargement du profil de l'utilisateur"
    ) ;
} ;