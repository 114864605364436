import { Center, StyleProps, Text, VStack } from "@chakra-ui/react";
import React, { FormEvent } from "react";

import { UUID } from "foundation-ts/types";
import { $count, $length, $ok } from "foundation-ts/commons";

import { SectionCategory } from "g1-commons/lib/doxecureTypes";
import { SectionDto } from "g1-commons/lib/doxecureClientTypes";
import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { SectionBox } from "../../components/SectionBox/SectionBox";
import { SectionBoxHeader } from "../../components/SectionBox/SectionBoxHeader";
import { SearchField } from "../../components/SearchField/SearchField";
import { SessionCard } from "../../components/Cards/SessionCard";

import { CustomItemType } from "../../utils/TypesAndConstants";

import { DashboardSession } from "./Dashboard";

export interface SectionsListProps {
    sections: SectionDto[] ;
    title: string ;
    emptyMessage?:string ;
    onSelect:  (apid: UUID) => void ;
    onEdit?:   (apid: UUID) => void ;
    onDelete?: (apid: UUID) => void ;
    onSearch?: (e: FormEvent) => void ; // if set we can do search in this object
    searchString?: string ;
    selectedSessionID?:UUID ;
    sectionInfos: (category: SectionCategory) => SectionInfos ;
}

export interface SectionInfos {
    style: StyleProps ;
    itemStyle: CustomItemType
    title?: string ;
    description?: string ;
}

export const SectionsList = ({ sections, title, emptyMessage = 'Pas de données', selectedSessionID, onSelect, sectionInfos, searchString = undefined, onSearch = undefined, onDelete = undefined, onEdit = undefined }: SectionsListProps) => {
    const showsSearchField = $ok(onSearch) ;
    let stylesCache = new Map<SectionCategory, StyleProps>() ;

    const sectionStyle = (category: SectionCategory): StyleProps => {
        let style = stylesCache.get(category) ;
        if (!$ok(style)) {
            style = { ...sectionInfos(category).style } ;
            style.overflow = style.overflow || 'hidden' ;
            style.fontWeight = style.fontWeight || 'bold' ;
            style.width = style.width || '100%' ;
            stylesCache.set(category, style) ;
        }
        return style! ;
    }

    const renderHeader = (category: SectionCategory) => {
        const infos = sectionInfos(category) ;
        if ($length(infos?.title) && $length(infos.description)) {
            return SectionBoxHeader({ title: infos.title!, description: infos.description!, color: infos.style.color, borderBottomColor: $g1Color('list.spacer') }) ;
        }
        return undefined ;
    }
    const renderItem = (item: DashboardSession, section?:SectionDto) => {
        return (
            <SessionCard
                item={item}
                itemStyle={$ok(section) ? sectionInfos(section!.category).itemStyle : undefined}
                displayMoreInfos={true}
                key={item.apid}
                onClick={() => onSelect(item.apid!)}
                onEdit={undefined}
                onDelete={undefined}
            />
        );
    };

    return (
        <>
            <ColumnTitle color={$g1Color('global.write')}>
                <Text>{title}</Text>
            </ColumnTitle>

            {showsSearchField && (
                <form onSubmit={onSearch}>
                    <SearchField component="form" mb="10%" h="40px" search={searchString} />
                </form>
            )}

            {$count(sections) > 0 ?
                (<VStack>
                    {
                        sections.map((section, index) => {
                            return (
                                <SectionBox
                                    key={index}
                                    sectionStyle={sectionStyle(section.category)}
                                    renderHeader={renderHeader(section.category)}
                                    parent={section}
                                    items={section.sessions}
                                    renderItem={renderItem}
                                />
                            );
                        })
                    }
                </VStack>) :
                (<Center width="100%" height="100%" color="gray"><h2>{emptyMessage}</h2></Center>)            
            }
        </>
    );
}
