import { Box, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, StyleProps, Switch, Text, Textarea, useToast, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, Dispatch, useState } from "react";

import { $count, $ok, $unsigned, $value } from "foundation-ts/commons";
import { $timeBetweenDates } from "foundation-ts/date";
import { $trim } from "foundation-ts/strings";
import { TSDate, TSDay } from "foundation-ts/tsdate";
import { TSDateForm } from "foundation-ts/tsdatecomp";
import { TSInterval } from "foundation-ts/tsinterval";
import { Descending, uint32, UUID } from "foundation-ts/types";
import { SessionDto, SignatureDto, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { SignaturePhase } from "g1-commons/lib/doxecureModelTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { UserCard } from "../../components/Cards/UserCard";
import { G1Input } from "../../components/G1Input";
import { SeaShellBox } from "../../components/SeaShellBox";
import { SectionBox } from "../../components/SectionBox/SectionBox";
import { SectionBoxFooter } from "../../components/SectionBox/SectionBoxFooter";

import { G1Icon } from "../../utils/icons";
import { optlog } from "../../utils/functions";
import { CustomItemType } from "../../utils/TypesAndConstants";
import { defaultErrorToast } from "../../utils/toast";

const contactsSectionStyle: StyleProps = {
    bg: $g1Color('list.contacts.bg'),
    color: $g1Color('list.contacts.write'),
    fontWeight: "bold",
    overflow: "hidden",
    rounded: "xl",
    width: "100%",
};

const contactsSectionStyleExpediteur: StyleProps = {
    ...contactsSectionStyle,
    bg: $g1Color('list.expeditors.bg'),
    color: $g1Color('list.expeditors.write'),
};

const itemExpeditorSectionStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.expeditors.hover.bg'), color: $g1Color('list.expeditors.hover.write') },
    select: { bg: $g1Color('list.expeditors.select.bg'), color: $g1Color('list.expeditors.select.write') },
}

const contactsSectionStyleSignataires = contactsSectionStyle;

const itemSignerSectionStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.contacts.hover.bg'), color: $g1Color('list.contacts.hover.write') },
    select: { bg: $g1Color('list.contacts.select.bg'), color: $g1Color('list.contacts.select.write') },
}

interface SignatureBoxProps {
    session: SessionDto ;
    optionInvisibleSwitchChecked?: boolean ;
    displayCalendar?: boolean ;
    displayDuringTime?: boolean ;
    onChangeSession: (oldSession:SessionDto, newSession:SessionDto) => void ;
    onAddSignerClick: () => void ;
    onAddSenderClick: () => void ;
    onRemoveSigner: (apid: UUID) => void ;
    onToggleInvisibleSwitch: () => void ;
    setSession: Dispatch<React.SetStateAction<SessionDto>> ; // FIXME
}
type SignatureBoxMove = "up" | "down";

const MIN_DURING_TIME = 3 ;
const MAX_DURING_TIME = 30 ;

export const SignatureBox = ({
    session,
    displayCalendar,
    displayDuringTime,
    optionInvisibleSwitchChecked,
    onChangeSession,
    onAddSenderClick,
    onAddSignerClick,
    onRemoveSigner,
    onToggleInvisibleSwitch,
    setSession,
}: SignatureBoxProps) => {
    const [duringTime, setDuringTime] = useState($ok(session) ? ($timeBetweenDates(session.validity.start.dateWithoutTime(), session.validity.end.dateWithoutTime()) / TSDay) : MIN_DURING_TIME) ;
    const toast = useToast() ;
    const endDate = session?.validity?.end?.dateWithoutTime() ;
    const dateDefaultRepresentation = $value<string>(endDate?.toString("%Y-%m-%d"), '') ;

    const renderSenderItem = (user: UserDto) => {
        return (
            <UserCard
                key={user.apid}
                item={user}
                itemStyle={itemExpeditorSectionStyle}
                onItemClick={onAddSenderClick}
            />
        );
    };

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        let value = $trim(e.target.value) ;
        if (value !== session.title) {
            const newSession:SessionDto = {
                ...session!,
                title: value
            };
            onChangeSession(session, newSession) ;
        }
    };

    const onChangeObject = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let value = $trim(e.target.value) ;
        if (value !== session.description) {
            const newSession:SessionDto = {
                ...session!,
                description: value
            };
            onChangeSession(session, newSession) ;
        }
    };

    const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
        optlog(`Did get new date value ${e.target.value} (${typeof e.target.value})`) ;
        const newEnd = TSDate.fromDateString(e.target.value, TSDateForm.Computer) ;
        const start = session?.validity?.start ;
        if ($ok(start) && $ok(newEnd)) {
            if (newEnd!.compare(start) !== Descending) {
                toast(defaultErrorToast("La date de fin de validité d'une session doit être ultérieure à la date de début de validité"));
                e.target.value = session?.validity?.end.toString("%Y-%m-%d") ;
            } else if ($timeBetweenDates(start.dateWithoutTime(), newEnd) < 3*TSDay) {
                toast(defaultErrorToast("La session doit avoir une durée minimale de 3 jours"));
                e.target.value = session?.validity?.end.toString("%Y-%m-%d") ;
            } else {
                const newSession:SessionDto = {
                    ...session!,
                    validity: new TSInterval(start, newEnd)
                };
                onChangeSession(session, newSession) ;
            }
        }
    }

    const onChangeDuringTime = (_valueAsString: string, valueAsNumber: number) => {
        const newDuringTime = (valueAsNumber < MIN_DURING_TIME) ? MIN_DURING_TIME : (valueAsNumber > MAX_DURING_TIME ? MAX_DURING_TIME : valueAsNumber) ;
        setDuringTime(newDuringTime) ;
        const now = TSDate.zulu() ;
        const newSession:SessionDto = {
            ...session!,
            validity: new TSInterval(now, now.dateByAddingDays(newDuringTime))
        };
        onChangeSession(session, newSession) ;
    }

    const handleMove = (item: SignatureDto, parentSession: SessionDto | undefined, index: number, move: SignatureBoxMove) => {
        // Pour se positionner entre les signataires
        // if (move === "down" && index < session.signatures.length - 1) {
        //   session.signatures;
        //   session.signatures.splice(index + 2, 0, item); //ajout
        //   session.signatures.splice(index, 1); // remove
        // } else if (move === "up" && index !== 0) {
        //   session.signatures.splice(index - 1, 0, item); //ajout
        //   session.signatures.splice(index + 1, 1); // remove
        // }

        // Déplacement début fin
        const N = $count(parentSession?.signatures);
        if (N > 1) {
            if (move === "down" && index + 1 < N) {
                // we pass the creator signer to the end of the list
                session.signatures!.push(item);
                session.signatures!.splice(index, 1);
                item.rank = 2 as uint32 ;
                const phase1 = session.signatures![1].phase ;
                const phaseNM2 = session.signatures![N-2].phase ;


                if (item.phase === SignaturePhase.Server && phaseNM2 === SignaturePhase.QSCDEnd) {
                    if (phase1 === SignaturePhase.Server) { 
                        item.phase = SignaturePhase.QSCDEnd ; // we change our phase because we cannot have Server - QSCDEnd - Server workflow 
                    }
                    else { 
                        // all previous QSCDEnd are now QSCDStart
                        for (let s of session.signatures!) { s.phase = SignaturePhase.QSCDStart ; }
                        item.phase = SignaturePhase.Server ;
                    }
                }
                else if (item.phase === SignaturePhase.QSCDStart && phaseNM2 !== item.phase) {
                    item.phase = SignaturePhase.QSCDEnd ;
                }

            } else if (move === "up" && index > 0) {
                // we pass the creator signer to the beginning of the list
                session.signatures!.splice(0, 0, item); 
                session.signatures!.splice(index + 1, 1); 
                const phase1 = session.signatures![1].phase ;
                const phaseNM2 = session.signatures![N-2].phase ;

                if (item.phase === SignaturePhase.QSCDEnd) { item.phase = SignaturePhase.QSCDStart ; }
                else if (item.phase === SignaturePhase.Server && phase1 === SignaturePhase.QSCDStart) {
                    if (phaseNM2 == SignaturePhase.QSCDStart) { 
                        // all previous QSCDStart are now QSCDEnd
                        for (let s of session.signatures!) { s.phase = SignaturePhase.QSCDEnd ; }
                        item.phase = SignaturePhase.Server ;
                    }
                    else {
                         // we change our phase because we cannot have Server - QSCDStart - Server workflow
                         item.phase = SignaturePhase.QSCDStart ;
                    }
                }
                item.rank = 0 as uint32 ;

            }

            setSession({
                ...session,
                signatures: session.signatures,
            });
        }
    };

    const renderSignatoryItem = (signature: SignatureDto, parentSession?: SessionDto, index?: number) => {
        const firstPhase = parentSession?.signatures?.first()?.phase ;
        const expeditorPhase = signature.phase ;

        const N = $count(parentSession?.signatures);
        const I = $unsigned(index);
        const item = signature.signer ;
        // FIXME: a better way to test the user apid and the current session
        if (!$ok(item.apid)) { throw '<SignatureBox>.renderSignatoryItem(): sognature.signer.apid should not be undefined'; }
        const renderUpDownButtons = () => {
            return (
                <Stack align="stretch" alignSelf="center">
                    {I > 0 && (
                        <Box margin="5%">
                            <G1Icon.Up size="20px" cursor="pointer" onClick={() => handleMove(signature, parentSession, I, "up")} />
                        </Box>
                    )}
                    {I + 1 < N && (
                        <Box margin="5%">
                            <G1Icon.Down size="20px" cursor="pointer" onClick={() => handleMove(signature, parentSession, I, "down")} />
                        </Box>
                    )}
                </Stack>
            );
        };
        return (
            <UserCard
                key={item.apid!}
                displayIcon
                item={item}
                itemStyle={itemSignerSectionStyle}
                locked={signature.phase === SignaturePhase.QSCDStart || signature.phase === SignaturePhase.QSCDEnd}
                onRemoveItemClick={() => onRemoveSigner(item.apid!)}
                upDownButtons={(item.apid === session.expeditor.apid) && (firstPhase === expeditorPhase) ? renderUpDownButtons() : null}
            />
        );
    };

    const renderAddSignerButton = () => {
        return <SectionBoxFooter component="contacts" title="Ajouter un signataire" onClick={onAddSignerClick} /> ;
    } ;

    // FIXME On doit bien remoplacer 'object' par 'title' ici ?
    return (
        <SeaShellBox component="form" spacing="10">
            <Text width="100%" fontWeight="bold">Dossier de signature</Text>

            <VStack width="100%">
                <Text width="100%">Titre de la signature</Text>
                <G1Input component="form" name="titleSession" placeholder="Nouvelle session" defaultValue={session.title} onChange={onChangeTitle} />
            </VStack>
            <VStack width="100%">
                <Text width="100%">Objet de la signature</Text>
                <Textarea
                    placeholder="Description optionnelle"
                    name="object"
                    defaultValue={session.description}
                    bg={$g1Color('form.field.bg')}
                    color={$g1Color('form.field.write')}
                    borderColor={$g1Color('form.border')}
                    _hover={{ borderColor: $g1Color('form.field.hover.border') }}
                    _focus={{ borderColor: $g1Color('form.field.select.border') }}
                    _placeholder={{ color: $g1Color('form.field.placeholder') }}
                    onChange={onChangeObject}
                ></Textarea>
            </VStack>

            {displayCalendar &&
                <VStack width="100%">
                    <Text width="100%">À signer avant le</Text>
                    <G1Input component="form" type="date" name="expiredAt" defaultValue={dateDefaultRepresentation} onChange={onChangeDate} />
                </VStack>
            }
            {displayDuringTime &&
                <HStack width="100%">
                    <Text>Nombre de jours de validité</Text>
                    <NumberInput value={duringTime} min={MIN_DURING_TIME} max={MAX_DURING_TIME} step={1} onChange={onChangeDuringTime}
                        width="10%"
                        bg={$g1Color(`form.field.bg`)}
                        color={$g1Color(`form.field.write`)}
                        borderColor={$g1Color(`form.field.border`)}
                        _hover={{ borderColor: $g1Color(`form.field.hover.border`) }}
                        _focus={{ borderColor: $g1Color(`form.field.select.border`) }}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </HStack>
            }
            <VStack width="100%" alignItems="normal">
                <Text>Options</Text>
                <HStack>
                    <Text fontSize='large'>Ne pas afficher les signatures</Text>
                    <Switch size="lg" isChecked={optionInvisibleSwitchChecked} onChange={onToggleInvisibleSwitch} />
                </HStack>
            </VStack>
            <VStack width="100%">
                <Text width="100%">Expéditeur</Text>
                <SectionBox
                    items={[session.expeditor]}
                    sectionStyle={contactsSectionStyleExpediteur}
                    renderItem={renderSenderItem}
                />
            </VStack>
            <VStack width="100%">
                <Text width="100%">Signataires</Text>
                <SectionBox
                    items={session.signatures}
                    sectionStyle={contactsSectionStyleSignataires}
                    parent={session}
                    renderItem={renderSignatoryItem}
                    renderFooter={renderAddSignerButton}
                />
            </VStack>
        </SeaShellBox>
    );
};
