import { Box, HStack } from "@chakra-ui/layout";
import React from "react";

import { $ok, $value } from "foundation-ts/commons";

import { SessionDto } from "g1-commons/lib/doxecureClientTypes";
import { SessionStatus, SignatureStatus } from "g1-commons/lib/doxecureModelTypes";

import { CustomItemType } from "../../utils/TypesAndConstants";
import { G1Icon } from "../../utils/icons";

import { CardDescription } from "./CardDescription";
import { TSDate } from "foundation-ts/tsdate";
import { $bool } from "foundation-ts/tsparser";

interface SessionCardProps {
    item: SessionDto ;
    itemStyle?: CustomItemType ;
    displayMoreInfos?: boolean ;
    onClick: () => void ;
    onEdit?: () => void ;
    onDelete?: () => void ;
}
export const SessionCard = (props: SessionCardProps) => {
    const {
        item: { status, title, description, expeditor, validity, signatures },
        itemStyle,
        displayMoreInfos,
        onClick,
        onEdit,
        onDelete,
    } = props ;
    const dateRepresentation = $value(validity?.end?.toLocaleString("%d/%m/%Y"), '') ;
    let moreInfos = "" ;

    switch (status) {
        case SessionStatus.ToBeApproved:
            moreInfos = `Transmis par ${expeditor.firstName} ${expeditor.lastName} et en attente d'approbation` ;
            break ;
        case SessionStatus.Signed: {
            const dateInfo = validity.containsDate(TSDate.zulu()) ? `signé le ${signatures!.last()!.signatureDate!.toLocaleString("%d/%m/%Y")}` : `archivé le ${dateRepresentation}` ;
            moreInfos = `Transmis par ${expeditor.firstName} ${expeditor.lastName} ${dateInfo}` ;
            break ;
        }
        case SessionStatus.Refused: {
            const refusedSignature = signatures?.find(signature => signature.signatureStatus === SignatureStatus.Refused) ;
            if ($ok(refusedSignature)) {
                const date = $ok(refusedSignature!.refusalDate) ? TSDate.fromIsoString(refusedSignature!.refusalDate) : refusedSignature!.signatureDate! ;
                moreInfos = `Refusé par ${refusedSignature!.signer.firstName.capitalize()} ${refusedSignature?.signer.lastName.toUpperCase()} le ${date?.toLocaleString("%d/%m/%Y")} pour le motif "${$ok(refusedSignature!.refusalReason) ? refusedSignature?.refusalReason : 'Refus'}"` ;
            } else {
                moreInfos = "Les informations sur le refus n'ont pas été trouvées" ;
            }
            break ;
        }
        case SessionStatus.Canceled: {
            const dateInfo = $ok(signatures?.last()?.signatureDate) ? `annulé le ${signatures!.last()!.signatureDate!.toLocaleString("%d/%m/%Y")}` : "- Information sur la date manquante" ;
            moreInfos = `Transmis par ${expeditor.firstName} ${expeditor.lastName} ${dateInfo}`;
            break ;
        }
        case SessionStatus.Draft:
            moreInfos = `Mis en brouillon par ${expeditor.firstName} ${expeditor.lastName} à signer avant le ${dateRepresentation}` ;
            break ;
        default:
            moreInfos = `Transmis par ${expeditor.firstName} ${expeditor.lastName} ${validity.containsDate(TSDate.zulu()) ? "à signer avant le" : "expiré le"} ${dateRepresentation}` ;
            break ;
    }

    return (
        <Box
            as="button"
            p="2"
            width="100%"
            minH="6em"
            borderTop={itemStyle?.borderTop ?? "1px"}
            borderTopColor={itemStyle?.borderTopColor ?? "inherit"}
            _hover={itemStyle?.hover}
            _focus={itemStyle?.select}
            onClick={onClick}
        >
            <Box width="100%" display="flex">
                <CardDescription width="100%" icon={expeditor.avatarUrl} title={title} description={description} moreInfos={$bool(displayMoreInfos) ? moreInfos : undefined} />
                <HStack margin="1%" alignSelf="center">
                    {$ok(onDelete)?<G1Icon.Delete size="24px" cursor="pointer" onClick={ (e)=> {e.preventDefault() ; onDelete!() ; }}/>:null}
                    {$ok(onEdit)?<G1Icon.Edit size="24px" cursor="pointer" onClick={ (e) => {e.preventDefault() ; onEdit!() ; }}/>:null}
                    <G1Icon.View size="24px" cursor="pointer" color={itemStyle?.iconColor ?? "inherit"} />
                </HStack>
            </Box>
        </Box>
    ) ;
} ;