import { $length, $ok } from "foundation-ts/commons";
import { TSDataLike } from "foundation-ts/types";
import { TSError } from "foundation-ts/tserrors";

import { BCADocumentFile, BCADocumentFileType, documentFileInfo } from "bca-client-signature/lib/BCADocuments";

import { blobToArrayBuffer, blobToBase64String } from "./functions";

export class BCABrowserDocumentFile implements BCADocumentFile
{
    public readonly name:string ;
    public readonly type:BCADocumentFileType ;
    public readonly mimes:string[] ;
    private _file:File ;

    constructor(file:File) {
        const fileInfo = documentFileInfo(file.name) ;
        if (!$ok(fileInfo)) { throw new TSError(`new BCADocumentFile(): impossible to get mime type of file '${file.name}'`) ; }
        this.name = fileInfo!.name ;
        this.mimes = fileInfo!.mimes ;
        this.type = fileInfo!.type ;
        this._file = file ;
    }

    public get fileName() { return this._file.name ; }

    public async getSize():Promise<number> { return this._file.size ; }

    public async toUrlData():Promise<string|undefined> {
        const s = await this.toBase64() ; 
        return $length(s) ? 'data:application/pdf;base64,'+s : undefined ; 
    }
    
    public async toBase64():Promise<string|undefined> { 
        const s = await blobToBase64String(this._file) ;
        return $length(s) ? s! : undefined ; 
    }
    
    public async toDataLike():Promise<TSDataLike|undefined> { 
        const ab = await blobToArrayBuffer(this._file) ;
        return $ok(ab) ? ab! : undefined ;  
    }

}
